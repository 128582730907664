<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="reservation-repeat-popup"
      @keyup.esc.stop="cancel"
      tabindex="-1"
    >
      <popup
        :title="'複数予約'"
        :invalid="waitFlg || lookOnlyFlg === 1"
        :shortFlg="true"
        @click-close-mark="cancel"
        @cancel="cancel"
        @decision="decision"
      >
        <template v-slot:content>
          <div class="reservation-repeat-content">
            <div class="wrapper">
              <div class="row">
                <div class="label">飼主</div>
                <div class="content info" data-test="owner">
                  <span class="show-id" v-if="reservation.ownerShowId">
                    {{ reservation.ownerShowId }}</span
                  >
                  {{ ownerFullName }}
                  <span v-if="ownerFullNameKana"
                    >（{{ ownerFullNameKana }}）</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="label">患者</div>
                <div class="content info" data-test="patient">
                  <span class="show-id" v-if="reservation.patientShowId">
                    {{ reservation.patientShowId }}</span
                  >
                  {{ reservation.patientName
                  }}<span v-if="reservation.patientNameKana"
                    >（{{ reservation.patientNameKana }}）</span
                  >
                </div>
              </div>
              <div class="row">
                <div class="label">予約列</div>
                <div class="content-short info first" data-test="column">
                  {{ reservationColumn }}
                </div>
                <div class="label">来院目的</div>
                <div class="content-short info" data-test="purpose">
                  {{ reservationPurpose }}
                </div>
              </div>
              <div class="row">
                <div class="label">診察内容</div>
                <div class="content-short info first" data-test="care">
                  {{ reservation.care }}
                </div>
                <div class="label">担当者</div>
                <div class="content-short info" data-test="staff">
                  {{ staffName }}
                </div>
              </div>
              <div class="row">
                <div class="label">メモ</div>
                <div class="content info" data-test="memo">
                  {{ reservation.memo }}
                </div>
              </div>
              <div class="reservation-list">
                <div v-for="(reservationItem, i) in reservations" :key="i">
                  <div class="row">
                    <div class="label">{{ i + 1 }}</div>
                    <div class="content input">
                      <date-time-input-form
                        class="date-time-input"
                        :date="reservationItem.date"
                        :startTime="reservationItem.startTime"
                        :endTime="reservationItem.endTime"
                        :minDate="minDate"
                        :maxDate="maxDate"
                        @input-data="setData(reservationItem.key, $event)"
                      />
                      <button
                        class="button-delete"
                        v-if="reservations.length > 1"
                        @click="deleteReservation(reservationItem.key)"
                        data-test="delete"
                      >
                        <img
                          src="@/assets/images/trash_orange.png"
                          class="button-icon"
                          width="16px"
                          height="16px"
                          alt="trash-orange"
                        />
                        <span class="button-text">削除</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="label"></div>
                  <div class="content">
                    <base-button-plus
                      class="add-button"
                      :text="'予約追加'"
                      @click="addReservation"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </popup>
      <announce-popup
        v-if="alertFlg"
        :type="type"
        :title="title"
        :buttons="buttons"
        :layerNumber="layerNumber"
        @close="closeAllPopups"
        @decision="backToReservationPopup"
        @cancel="closePopup"
        >{{ popupMessage }}</announce-popup
      >
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import DateTimeInputForm from '@/components/parts/molecules/DateTimeInputForm'
import { FocusTrap } from 'focus-trap-vue'
import BaseButtonPlus from '@/components/parts/atoms/BaseButtonPlus'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import { mapGetters } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'ReservationRepeatPopup',
  components: {
    Popup,
    AnnouncePopup,
    FocusTrap,
    DateTimeInputForm,
    BaseButtonPlus
  },
  mixins: [CheckPopupInputDifference],
  props: {
    reservation: { type: Object, required: true },
    originalPopupPreventLeaveFlg: { type: Boolean }
  },
  data() {
    return {
      alertFlg: false,
      waitFlg: false,
      title: '',
      buttons: ['閉じる'],
      type: '',
      popupMessage: '',
      key: 0,
      layerNumber: 3,
      reservations: [],
      initialReservation: {},
      sendMailFlg: false,
      noDataFlg: false,
      closeAllPopupsFlg: false
    }
  },
  computed: {
    ...mapGetters({
      getColumn: 'reservationColumns/getDataById',
      getPurpose: 'reservationPurposes/getDataById',
      getStaff: 'staffs/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    diffFlg() {
      return this.originalPopupPreventLeaveFlg
        ? true
        : this.reservations.length !== 1 ||
            !_.isEqual(this.initialReservation, this.reservations[0])
    },
    originalReservation() {
      const originalReservation = {
        ...this.reservation,
        medicalRecordOriginalId: 0,
        groupKey: '',
        repeatKey: ''
      }
      delete originalReservation.id
      return originalReservation
    },
    minDate() {
      return new Date()
    },
    maxDate() {
      return moment(this.reservation.date, 'YYYYMMDD')
        .add(1, 'year')
        .toDate()
    },
    ownerFullName() {
      return `${this.reservation.ownerLastName} ${this.reservation.ownerFirstName}`
    },
    ownerFullNameKana() {
      if (
        this.reservation.ownerLastNameKana ||
        this.reservation.ownerFirstNameKana
      ) {
        return `${this.reservation.ownerLastNameKana} ${this.reservation.ownerFirstNameKana}`
      } else return ''
    },
    reservationColumn() {
      return this.getColumn(this.reservation.reservationColumnId)?.name || ''
    },
    reservationPurpose() {
      return this.getPurpose(this.reservation.reservationPurposeId)?.name || ''
    },
    staffName() {
      const staff = this.getStaff(this.reservation.staffId)
      return staff ? `${staff.lastName} ${staff.firstName}` : ''
    }
  },
  created() {
    this.reservations.push({
      ...this.reservation,
      groupKey: '',
      repeatKey: '',
      key: 0
    })
    this.initialReservation = _.cloneDeep(this.reservations[0])
  },
  beforeDestroy() {
    if (this.originalPopupPreventLeaveFlg && !this.closeAllPopupsFlg) {
      this.$store.dispatch('petorelu/popupNgLeave')
    }
  },
  methods: {
    cancel() {
      if (
        this.reservations.length === 1 &&
        _.isEqual(this.initialReservation, this.reservations[0])
      ) {
        this.$emit('cancel')
      } else {
        this.title = '確認'
        this.type = 'alert'
        this.popupMessage =
          '入力内容を保存せずに閉じようとしています。\nよろしいですか？'
        this.buttons = ['キャンセル', '閉じる']
        this.layerNumber = 4
        this.alertFlg = true
      }
    },
    async decision() {
      this.waitFlg = true
      const sendReservations = _.cloneDeep(this.reservations)
      sendReservations.forEach(v => {
        delete v.ownerShowId
        delete v.patientShowId
        delete v.key
      })
      const res = await this.$store.dispatch('reservations/repeat', {
        reservations: sendReservations
      })
      if (res.result === 'success') {
        this.title = '完了'
        this.type = 'success'
        this.sendMailFlg = res.sendMailFlg
        const reservationList = this.makeReservationList()
        this.popupMessage = `以下の予約を作成しました。\n${reservationList}`
        this.$store.dispatch('timeTable/setHighlightedReservation', {
          ids: res.reservationIds,
          type: 'createUpdate'
        })
      } else {
        this.title = '失敗'
        this.type = 'failure'
        if (res === 'currently used') {
          this.popupMessage = '元の予約にカルテがある為、失敗しました'
          this.noDataFlg = true
        } else {
          this.popupMessage = '失敗しました'
        }
      }
      this.buttons = ['閉じる']
      this.alertFlg = true
      this.waitFlg = false
    },
    makeReservationList() {
      let reservationList = ''
      this.reservations.forEach(r => {
        reservationList +=
          '\n' +
          moment(r.date + r.startTime, 'YYYYMMDDHHmm').format(
            'YYYY年MM月DD日　HH:mm'
          ) +
          '～' +
          moment(r.endTime, 'HHmm').format('HH:mm')
      })
      return reservationList
    },
    removeColon(time) {
      return time
        .split(':')
        .join('')
        .padStart(4, '0')
    },
    setData(key, { field, value }) {
      if (value) {
        const index = this.reservations.findIndex(r => r.key === key)
        this.$set(this.reservations[index], field, value)
      }
    },
    addReservation() {
      this.reservations.push({ ...this.originalReservation, key: ++this.key })
    },
    deleteReservation(key) {
      const index = this.reservations.findIndex(r => r.key === key)
      this.reservations = this.reservations
        .slice(0, index)
        .concat(this.reservations.slice(index + 1))
    },
    closeAllPopups() {
      this.closeAllPopupsFlg = true
      if (this.noDataFlg) {
        this.$store.dispatch('timeTable/deletePopup') // 予約ポップアップごと閉じる
      } else {
        this.alertFlg = false
        this.$emit('close', {
          result: this.type,
          sendMailFlg: this.sendMailFlg
        }) // -> TimeTable.vue で予約ポップアップごと閉じる
      }
    },
    closePopup() {
      this.alertFlg = false
    },
    backToReservationPopup() {
      this.alertFlg = false
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-repeat-popup {
  .reservation-repeat-content {
    width: 700px;
    > .wrapper {
      width: 640px;
      height: 450px;
      text-align: left;
      font-size: 15px;
      .row {
        width: 640px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        > .label {
          width: 100px;
          font-weight: bold;
        }
        > .content {
          width: 500px;
          &.info {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          &.input {
            display: flex;
            align-items: center;
          }
          > .show-id {
            margin-right: 10px;
          }
          > .date-time-input {
            width: 350px;
          }
          > .button-delete {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 33px;
            padding: 0;
            border-radius: 6px;
            border: solid 2px #{$light-grey};
            background-color: #{$white};
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
            &:active {
              height: 31px;
            }
            > .button-icon {
              position: relative;
              width: 16px;
              height: 16px;
              margin: 0 8px;
            }
            > .button-text {
              font-size: 13px;
              font-weight: bold;
              margin-right: 8px;
              color: #{$greyish-brown};
            }
          }
          > .add-button {
            margin-left: 225px;
          }
        }
        > .content-short {
          width: 190px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          &.first {
            margin-right: 20px;
          }
        }
      }
      > .reservation-list {
        height: 260px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
}
</style>
