<template>
  <div class="base-time-input-field">
    <vue-timepicker
      :value="value"
      :format="format"
      :hour-range="hourRange"
      :minute-range="minuteRange"
      :minute-interval="minuteInterval"
      :hour-label="hourLabel"
      :minute-label="minuteLabel"
      :input-width="inputWidth"
      :hide-clear-button="hideFlg"
      :disabled="disableFlg"
      :hide-disabled-hours="hideDisabledHours"
      :hide-disabled-minutes="hideDisabledMinutes"
      auto-scroll
      class="timepicker"
      @input="inputTime"
      data-e2e="base-time-input-field"
    ></vue-timepicker>
  </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  name: 'BaseTimeInputField',

  components: {
    VueTimepicker
  },

  props: {
    value: {
      type: [String, Object], //Objectの場合、{ HH: '00', mm: '00' }の形で渡さないとエラーになる
      default: '00:00',
      validator: function(val) {
        if (typeof val === 'string') {
          const h = parseInt(val.split(':')[0])
          const m = parseInt(val.split(':')[1])
          return 0 <= h && h <= 23 && 0 <= m && m <= 59
        } else {
          const h = parseInt(val.HH)
          const m = parseInt(val.mm)
          return 0 <= h && h <= 23 && 0 <= m && m <= 59
        }
      }
    },
    format: { type: String, default: 'H:mm' },
    hourRange: {
      type: Array,
      default: function() {
        return [[0, 23]]
      }
    },
    minuteRange: {
      type: Array,
      default: function() {
        return [[0, 59]]
      }
    },
    minuteInterval: { type: Number, default: 5 },
    hourLabel: { type: String, default: '時' },
    minuteLabel: { type: String, default: '分' },
    inputWidth: { type: String, default: '77px' },
    hideFlg: { type: Boolean, default: true },
    disableFlg: { type: Boolean, default: false },
    hideDisabledHours: { type: Boolean, default: true },
    hideDisabledMinutes: { type: Boolean, default: true }
  },

  methods: {
    inputTime(e) {
      this.$emit('input', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-time-input-field {
  display: inline-block;
  > .timepicker {
    ::v-deep input {
      height: 33px;
      padding: 6px;
      border: solid 2px #{$light-grey};
      border-radius: 4px;
      box-sizing: border-box;
      font-size: 13px;
      text-align: center;
    }
    ::v-deep .dropdown {
      height: auto;
      max-height: 10em;
      margin-top: 4px;
      margin-bottom: 10px;
      font-size: 12px;
      border-radius: 3px;
      > .select-list {
        height: auto;
        max-height: 10em;
        > ul .active {
          background: #{$pumpkin};
        }
      }
    }
  }
}
</style>
