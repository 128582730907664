<template>
  <div class="date-time-input-form" :style="styles">
    <div class="date-input-field">
      <v-date-picker
        :value="dispDate"
        :min-date="minDate"
        :max-date="maxDate"
        :input-props="{
          class: 'v-date-picker',
          readonly: true,
          disabled: dateDisabled
        }"
        :popover="popover"
        is-required
        data-test="v-date-picker"
        @input="inputDate"
      />
    </div>
    <time-period-input-field
      :startTime="dispStartTime"
      :endTime="dispEndTime"
      :startHourRange="startHourRange"
      :startMinuteRange="startMinuteRange"
      :endHourRange="endHourRange"
      :endMinuteRange="endMinuteRange"
      :startHideDisabledHours="false"
      :startHideDisabledMinutes="false"
      :endHideDisabledHours="false"
      :endHideDisabledMinutes="false"
      :input-width="'70px'"
      @input-start="inputStartTime"
      @input-end="inputEndTime"
    />
  </div>
</template>

<script>
import TimePeriodInputField from '@/components/parts/molecules/TimePeriodInputField'
import moment from 'moment'

export default {
  name: 'DateTimeInputForm',
  components: { TimePeriodInputField },
  props: {
    date: { type: String, required: true }, // YYYYMMDDHHmm
    minDate: { type: Date, default: null },
    maxDate: { type: Date, default: null },
    popover: { type: Object },
    startTime: { type: String, required: true }, // HHmm
    endTime: { type: String, required: true }, // HHmm
    dateDisabled: { type: Boolean, default: false },
    styles: { type: Object },
    startTimeMin: { type: String, default: '' }, // HHmm
    startTimeMax: { type: String, default: '' }, // HHmm
    endTimeMin: { type: String, default: '' }, // HHmm
    endTimeMax: { type: String, default: '' } // HHmm
  },
  data() {
    return {
      startHourRange: [[0, 23]],
      startMinuteRange: [[0, 59]],
      endHourRange: [[0, 23]],
      endMinuteRange: [[0, 59]]
    }
  },
  computed: {
    dispDate() {
      return this.stringToDate(this.date)
    },
    dispStartTime() {
      return this.insertColon(this.startTime)
    },
    dispEndTime() {
      return this.insertColon(this.endTime)
    }
  },
  watch: {
    startTimeMin() {
      this.setRange()
    },
    startTimeMax() {
      this.setRange()
    },
    endTimeMin() {
      this.setRange()
    },
    endTimeMax() {
      this.setRange()
    }
  },
  methods: {
    stringToDate(date) {
      return moment(date, 'YYYYMMDD').toDate()
    },
    insertColon(time) {
      return time.slice(0, 2) + ':' + time.slice(2)
    },
    removeColon(time) {
      return time
        .split(':')
        .join('')
        .padStart(4, '0')
    },
    inputDate(val) {
      this.$emit('input-data', {
        field: 'date',
        value: moment(val).format('YYYYMMDD')
      })
    },
    inputStartTime(val) {
      const minuteDiff = moment(this.endTime, 'HHmm').diff(
        moment(this.startTime, 'HHmm'),
        'minutes'
      )
      let endTime = moment(val, 'HH:mm')
        .add(minuteDiff, 'minutes')
        .format('HHmm')
      endTime = this.toCorrectEnd(endTime, minuteDiff)
      if (moment(endTime, 'HHmm').diff(moment(val, 'HH:mm')) < 0) {
        endTime = '2355'
      }
      this.$emit('input-data', { field: 'endTime', value: endTime })
      const startTime = this.removeColon(val)
      const value = this.toCorrectStart(startTime)
      this.$emit('input-data', { field: 'startTime', value })
      this.$nextTick(() => {
        this.setRange()
      })
    },
    inputEndTime(val) {
      const endTime = this.removeColon(val)
      const value = this.toCorrectEnd(endTime)
      this.$emit('input-data', { field: 'endTime', value })
      this.$nextTick(() => {
        this.setRange()
      })
    },
    toStartHourMin() {
      const base = '0'
      let hour = ''
      if (this.startTimeMin !== '') {
        hour = moment(this.startTimeMin, 'HHmm').format('HH')
      }
      const value = hour === '' ? base : hour
      return value
    },
    toStartMinuteMin(startHour) {
      const base = '0'
      let minute = ''
      if (this.startTimeMin !== '') {
        const minMinute = moment(this.startTimeMin, 'HHmm').format('HH')
        if (startHour === minMinute) {
          minute = moment(this.startTimeMin, 'HHmm').format('mm')
        }
      }
      const value = minute === '' ? base : minute
      return value
    },
    toStartHourMax(startMinute) {
      const base = startMinute === '55' ? '22' : '23'
      let hour = ''
      if (this.endTimeMax !== '') {
        hour = moment(this.endTimeMax, 'HHmm')
          .subtract(5, 'minutes')
          .format('HH')
      }
      if (this.startTimeMax !== '') {
        hour = moment(this.startTimeMax, 'HHmm').format('HH')
      }
      const value = hour === '' ? base : hour
      return value
    },
    toStartMinuteMax(startHour) {
      const base = startHour === '23' ? '50' : '59'
      let minute = ''
      if (this.endTimeMax !== '') {
        const maxHour = moment(this.endTimeMax, 'HHmm')
          .subtract(5, 'minutes')
          .format('HH')
        if (startHour === maxHour) {
          minute = moment(this.endTimeMax, 'HHmm')
            .subtract(5, 'minutes')
            .format('mm')
        }
      }
      if (this.startTimeMax !== '') {
        const maxHH = moment(this.startTimeMax, 'HHmm').format('HH')
        if (startHour === maxHH) {
          minute = moment(this.startTimeMax, 'HHmm').format('mm')
        }
      }
      const value = minute === '' ? base : minute
      return value
    },
    toEndHourMin(startHour, startMinute, endMinute) {
      const base =
        endMinute <= startMinute ? `${Number(startHour) + 1}` : startHour
      let hour = ''
      if (this.endTimeMin !== '') {
        hour = moment(this.endTimeMin, 'HHmm').format('HH')
      }
      const value =
        hour === '' ? base : Number(hour) < Number(base) ? base : hour
      return value
    },
    toEndMinuteMin(startHour, endHour, startMinute) {
      const base = startHour === endHour ? `${Number(startMinute) + 5}` : '0'
      let minute = ''
      if (this.endTimeMin !== '') {
        const minHour = moment(this.endTimeMin, 'HHmm').format('HH')
        const minMinute = moment(this.endTimeMin, 'HHmm').format('mm')
        minute = endHour === minHour ? minMinute : '0'
      }
      const value =
        minute === '' ? base : Number(minute) < Number(base) ? base : minute
      return value
    },
    toEndHourMax() {
      const base = '23'
      let hour = ''
      if (this.endTimeMax !== '') {
        hour = moment(this.endTimeMax, 'HHmm').format('HH')
      }
      const value = hour === '' ? base : hour
      return value
    },
    toEndMinuteMax(endHour) {
      const base = '59'
      let minute = ''
      if (this.endTimeMax !== '') {
        const maxHour = moment(this.endTimeMax, 'HHmm').format('HH')
        if (endHour === maxHour) {
          minute = moment(this.endTimeMax, 'HHmm').format('mm')
        }
      }
      const value = minute === '' ? base : minute
      return value
    },
    setRange() {
      // start
      const startTime = this.startTime
      const startHour = moment(startTime, 'HHmm').format('HH')
      const startMinute = moment(startTime, 'HHmm').format('mm')
      const startHourMin = this.toStartHourMin()
      const startHourMax = this.toStartHourMax(startMinute)
      const startMinuteMin = this.toStartMinuteMin(startHour)
      const startMinuteMax = this.toStartMinuteMax(startHour)
      this.startHourRange = [[startHourMin, startHourMax].map(Number)]
      this.startMinuteRange = [[startMinuteMin, startMinuteMax].map(Number)]
      const correctStart = this.toCorrectStart(startTime)
      if (startTime !== correctStart) {
        this.$emit('input-data', { field: 'startTime', value: correctStart })
      }
      // end
      const endTime = this.endTime
      const endHour = moment(endTime, 'HHmm').format('HH')
      const endMinute = moment(endTime, 'HHmm').format('mm')
      const endHourMin = this.toEndHourMin(startHour, startMinute, endMinute)
      const endHourMax = this.toEndHourMax()
      const endMinuteMin = this.toEndMinuteMin(startHour, endHour, startMinute)
      const endMinuteMax = this.toEndMinuteMax(endHour)
      this.endHourRange = [[endHourMin, endHourMax].map(Number)]
      this.endMinuteRange = [[endMinuteMin, endMinuteMax].map(Number)]
      const correctEnd = this.toCorrectEnd(endTime, 5)
      if (endTime !== correctEnd) {
        this.$emit('input-data', { field: 'endTime', value: correctEnd })
      }
    },
    toCorrectStart(startTime) {
      let start = startTime
      if (this.startTimeMin !== '' && startTime < this.startTimeMin) {
        start = this.startTimeMin
      }
      if (this.startTimeMax !== '' && this.startTimeMax < startTime) {
        start = this.startTimeMax
      }
      if (this.endTimeMax !== '' && this.endTimeMax <= startTime) {
        start = moment(this.endTimeMax, 'HHmm')
          .subtract(5, 'minutes')
          .format('HHmm')
      }
      return start
    },
    toCorrectEnd(endTime, minuteDiff = 0) {
      let end = endTime
      if (this.startTimeMin !== '' && endTime <= this.startTimeMin) {
        end = moment(this.startTimeMin, 'HHmm')
          .add(minuteDiff, 'minutes')
          .format('HHmm')
      }
      if (this.endTimeMin !== '' && endTime < this.endTimeMin) {
        end = this.endTimeMin
      }
      if (this.endTimeMax !== '' && this.endTimeMax < endTime) {
        end = this.endTimeMax
      }
      return end
    }
  }
}
</script>

<style lang="scss" scoped>
.date-time-input-form {
  display: flex;
  width: 300px;
  align-items: center;
  > .date-input-field {
    margin-right: 5px;
    ::v-deep span .v-date-picker {
      width: 140px;
      height: 33px;
      padding-right: 5px;
      border-radius: 5px;
      border: solid 2px #{$light-grey};
      font-size: 13px;
      text-align: right;
      cursor: pointer;
      background-image: url('../../../assets/images/calendar.png');
      background-size: 18px 18px;
      background-repeat: no-repeat;
      background-position: 5px center;
      &:disabled {
        background-color: #{$light-grey};
        color: #{$brown-gray};
        opacity: 0.7;
        cursor: default;
      }
    }
  }
}
</style>
