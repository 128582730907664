<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="same-name-owner-patients-select-popup"
      @keyup.esc.stop="$emit('close')"
      tabindex="-1"
    >
      <popup
        :closeMarkFlg="false"
        :title="'患者の選択'"
        :shortFlg="true"
        :buttons="['キャンセル', '登録']"
        :disabledFlg="supportFlg === 1"
        :invalid="selectPatientId === 0 || lookOnlyFlg === 1"
        @cancel="$emit('close')"
        @decision="decision"
      >
        <template v-slot:content>
          <div class="content">
            <div class="text-area">
              <div>患者を選択して下さい。</div>
            </div>
            <div class="radio-button-area">
              <base-radio-button
                class="base-radio-button"
                v-for="datum in patientRadioButtonData"
                :key="datum.id"
                :option="datum"
                v-model="selectPatientId"
                :styles="{ marginBottom: '12px', textAlign: 'left' }"
                :inputStyles="{ whiteSpace: 'pre-wrap' }"
              />
            </div>
          </div>
        </template>
      </popup>
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton'
import { FocusTrap } from 'focus-trap-vue'
import { mapGetters } from 'vuex'
import { compareShowId } from '@/utils/sort_show_id'

export default {
  name: 'SameNameOwnerPatientsSelectPopup',
  components: {
    Popup,
    BaseRadioButton,
    FocusTrap
  },
  props: {
    ownerId: { type: Number },
    dispPatientId: { type: Number },
    ownerCount: { type: Number }
  },
  data() {
    return {
      selectPatientId: 0
    }
  },
  computed: {
    ...mapGetters({
      lookOnlyFlg: 'auth/lookOnlyFlg',
      supportFlg: 'auth/supportFlg',
      getOwnerPatients: 'patients/getDataByOwnerId',
      getPatient: 'patients/getDataById',
      getSpecies: 'species/getDataById'
    }),
    newPatient() {
      return this.getPatient(this.dispPatientId)
    },
    patientRadioButtonData() {
      const sortedPatients = this.sortPatients(
        this.getOwnerPatients(this.ownerId) || []
      )
      return [this.newPatient].concat(sortedPatients).map((patient, i) => {
        const { id, showId, speciesId, breed, name } = patient
        const newPatientText = this.dispPatientId === id ? '新規患者　' : ''
        const idText = showId ? `ID:${showId}` + '　' : 'ID:未設定　'
        const speciesName = this.getSpecies(speciesId)?.name || ''
        const speciesBreedText = breed
          ? '（' + speciesName + '' + '、' + breed + '）'
          : '（' + speciesName + '）'
        return {
          id: this.ownerCount + i + 1, // 同姓同名飼主の選択ポップアップにあるラジオボタンのidと被らないようにする
          eachValue: id,
          labelName: newPatientText + idText + name + speciesBreedText
        }
      })
    }
  },
  methods: {
    sortPatients(patients) {
      return patients.slice().sort((a, b) => {
        return compareShowId(a.showId, b.showId)
      })
    },
    decision() {
      this.$emit('changeToExistingOwnerWithSameName', {
        ownerId: this.ownerId,
        patientId: this.selectPatientId
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.same-name-owner-patients-select-popup {
  .content {
    width: 600px;
    min-height: 300px;
    font-size: 16px;
    > .text-area {
      margin-bottom: 10px;
      font-weight: bold;
    }
    > .radio-button-area {
      overflow: auto;
      max-height: 345px;
    }
  }
}
</style>
