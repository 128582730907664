<template>
  <div
    class="base-exclamation-mark"
    :title="title"
    :style="styles"
    data-test="base-exclamation-mark"
  >
    !
  </div>
</template>

<script>
export default {
  name: 'BaseExclamationMark',
  props: {
    title: { type: String, default: '' },
    styles: {
      type: Object,
      validator: obj => {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.base-exclamation-mark {
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  background: #{$tomato};
  color: #{$white};
  font-weight: bold;
  font-size: 13px;
}
</style>
