<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="same-name-owners-select-popup"
      @keyup.esc.stop="$emit('close')"
      tabindex="-1"
    >
      <popup
        :closeMarkFlg="false"
        :title="'同姓同名の飼主の選択'"
        :shortFlg="true"
        :buttons="['キャンセル', '決定']"
        :invalid="waitFlg"
        @cancel="$emit('close')"
        @decision="decision"
      >
        <template v-slot:content>
          <div class="content">
            <div class="text-area">
              <div>既に同姓同名の飼主様が登録されています。</div>
              <div>新規飼主の予約として登録しますか？</div>
              <div>それとも既存飼主の予約として登録しますか？</div>
              <div>
                ※既存飼主の予約として登録した際、新規飼主で他に予約申請・予約がない場合、新規飼主を削除します。
              </div>
            </div>
            <div class="radio-button-area">
              <base-radio-button
                class="base-radio-button"
                v-for="datum in ownerRadioButtonData"
                :key="datum.id"
                :option="datum"
                v-model="selectOwnerId"
                :styles="{ marginBottom: '12px', textAlign: 'left' }"
                :inputStyles="{ whiteSpace: 'pre-wrap' }"
              />
            </div>
          </div>
        </template>
      </popup>
      <same-name-owner-patients-select-popup
        v-if="patientsSelectPopupFlg"
        :ownerId="selectOwnerId"
        :dispPatientId="dispPatientId"
        :ownerCount="ownerRadioButtonData.length"
        @close="patientsSelectPopupFlg = false"
        @changeToExistingOwnerWithSameName="
          $emit('changeToExistingOwnerWithSameName', $event)
        "
      ></same-name-owner-patients-select-popup>
      <announce-popup
        v-if="alertFlg"
        :title="title"
        :buttons="buttons"
        :type="type"
        @close="alertFlg = false"
        >{{ popupMessage }}</announce-popup
      >
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import BaseRadioButton from '@/components/parts/atoms/BaseRadioButton'
import SameNameOwnerPatientsSelectPopup from '@/components/popups/SameNameOwnerPatientsSelectPopup'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { compareShowId } from '@/utils/sort_show_id'
import { FocusTrap } from 'focus-trap-vue'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'SameNameOwnersSelectPopup',
  components: {
    Popup,
    BaseRadioButton,
    SameNameOwnerPatientsSelectPopup,
    AnnouncePopup,
    FocusTrap
  },
  props: {
    sameFullNameOwners: {
      type: Array,
      default: function() {
        return []
      }
    },
    dispOwnerId: { type: Number },
    dispPatientId: { type: Number },
    dispReservationId: { type: Number }
  },
  data() {
    return {
      selectOwnerId: this.dispOwnerId,
      patientsSelectPopupFlg: false,
      alertFlg: false,
      waitFlg: false,
      type: 'failure',
      title: '失敗',
      buttons: ['閉じる'],
      popupMessage: '通信エラーが発生しました。'
    }
  },
  computed: {
    ...mapGetters({
      getOwnerPatients: 'patients/getDataByOwnerId',
      getPatient: 'patients/getDataById',
      getMasterDatum: 'master/getDataById'
    }),
    newPatient() {
      return this.getPatient(this.dispPatientId)
    },
    ownerRadioButtonData() {
      return this.sortOwners(this.sameFullNameOwners).map((owner, i) => {
        const {
          id: ownerId,
          showId,
          lastName,
          firstName,
          lastNameKana,
          firstNameKana,
          email,
          tel,
          birthday,
          prefectureId,
          address,
          building,
          note
        } = owner
        const isNewOwner = this.dispOwnerId === ownerId
        const newOwnerText = isNewOwner ? '新規飼主　' : ''
        const ownerIdText = showId ? `ID:${showId}` + '　' : 'ID:未設定　'
        const ownerNameText = `${lastName} ${firstName}(${lastNameKana} ${firstNameKana})\n`
        const patients = isNewOwner
          ? this.newPatient
            ? [this.newPatient] // ←新規飼主の場合は複数患者がいても開いている予約の患者の情報だけ表示する
            : []
          : this.getOwnerPatients(ownerId) || []
        const patientText =
          patients.length > 0
            ? 'ペット名：' +
              patients.reduce((patientNames, patient, i) => {
                return i === 0
                  ? patient.name
                  : patientNames + '、' + patient.name
              }, '')
            : 'ペット登録なし'
        const convertedBirthday = birthday
          ? moment(birthday).format('YYYY年MM月DD日')
          : ''
        const prefName =
          this.getMasterDatum('prefectures', prefectureId)?.name || ''
        return {
          id: i + 1,
          eachValue: ownerId,
          labelName: newOwnerText + ownerIdText + ownerNameText + patientText,
          title: `Eメールアドレス: ${email}\n電話番号: ${tel}\n生年月日: ${convertedBirthday}\n住所: ${prefName}${address}${building}\n備考: ${note}`
        }
      })
    }
  },
  methods: {
    sortOwners(sameFullNameOwners) {
      return sameFullNameOwners.slice().sort((a, b) => {
        return this.dispOwnerId === a.id
          ? -1
          : this.dispOwnerId === b.id
          ? 1
          : compareShowId(a.showId, b.showId)
      })
    },
    async decision() {
      if (this.selectOwnerId === this.dispOwnerId) {
        this.waitFlg = true
        const result = await this.$store.dispatch(
          'reservations/updateDuplicateCheckShowFlgToZero',
          { id: this.dispReservationId }
        )
        if (result === true) {
          this.$emit('closeAndUpdateDuplicateCheckShowFlgToZero')
        } else {
          this.alertFlg = true
        }
        this.waitFlg = false
      } else {
        this.patientsSelectPopupFlg = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.same-name-owners-select-popup {
  .content {
    width: 600px;
    min-height: 300px;
    font-size: 16px;
    > .text-area {
      margin-bottom: 10px;
      font-weight: bold;
    }
    > .radio-button-area {
      overflow: auto;
      max-height: 297px;
    }
  }
}
</style>
