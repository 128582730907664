<template>
  <div class="time-period-input-field">
    <div class="column start">
      <base-time-input-field
        :value="startTime"
        :hour-range="startHourRange"
        :minute-range="startMinuteRange"
        :input-width="inputWidth"
        v-bind="startConditions"
        @input="inputStartTime"
        :hideDisabledHours="startHideDisabledHours"
        :hideDisabledMinutes="startHideDisabledMinutes"
      />
    </div>
    <div class="column">〜</div>
    <div class="column end">
      <base-time-input-field
        :value="endTime"
        :hour-range="endHourRange"
        :minute-range="endMinuteRange"
        :input-width="inputWidth"
        v-bind="endConditions"
        @input="inputEndTime"
        :hideDisabledHours="endHideDisabledHours"
        :hideDisabledMinutes="endHideDisabledMinutes"
      />
    </div>
  </div>
</template>

<script>
import BaseTimeInputField from '@/components/parts/atoms/BaseTimeInputField'

export default {
  name: 'TimePeriodInputField',

  components: { BaseTimeInputField },

  props: {
    startTime: { type: String },
    endTime: { type: String },
    startHourRange: { type: Array },
    startMinuteRange: { type: Array },
    endHourRange: { type: Array },
    endMinuteRange: { type: Array },
    startConditions: {
      type: Object,
      default: function() {
        return {}
      }
    },
    endConditions: {
      type: Object,
      default: function() {
        return {}
      }
    },
    inputWidth: { type: String },
    startHideDisabledHours: { type: Boolean, default: true },
    startHideDisabledMinutes: { type: Boolean, default: true },
    endHideDisabledHours: { type: Boolean, default: true },
    endHideDisabledMinutes: { type: Boolean, default: true }
  },

  methods: {
    inputStartTime(e) {
      this.$emit('input-start', e)
    },
    inputEndTime(e) {
      this.$emit('input-end', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.time-period-input-field {
  display: flex;
  align-items: center;
  width: 190px;
  > .start {
    margin-right: 9px;
  }
  > .end {
    margin-left: 9px;
  }
}
</style>
