<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div class="reservation-popup" @keyup.esc.stop="cancel" tabindex="-1">
      <validation-observer class="validation-observer" v-slot="{ invalid }">
        <popup
          :title="'予約'"
          :disabledFlg="dispReservation.reservationColumnId === 0"
          :invalid="invalid || waitFlg || lookOnlyFlg === 1"
          :shortFlg="true"
          :headerStyles="{ marginTop: '5px', paddingBottom: '5px' }"
          :contentStyle="{ padding: '0' }"
          @click-close-mark="cancel"
          @cancel="cancel"
          @decision="decision"
        >
          <template v-slot:content>
            <div class="reservation-content">
              <reservation-popup-edit-header
                :beforeReservation="beforeReservation"
                :cancelMailAuto="reservationSetting.cancelMailAuto"
                :dispReservation="dispReservation"
                :initialOwnerEmail="initialOwnerEmail"
                :invalidFlg="invalid || waitFlg"
                :lookOnlyFlg="lookOnlyFlg"
                :reservationId="reservationId"
                @delete="deleteReservation"
                @changeWaitingStatus="changeWaitingStatus"
                @showOwner="showOwner"
                @newOwner="newOwner"
                @openRepeatPopup="openRepeatPopup"
              />
              <div class="reservation-body">
                <div class="row date-column">
                  <div class="info-label-wrapper date">
                    <span class="info-label">予約日時</span>
                  </div>
                  <div class="info-content-wrapper date">
                    <date-time-input-form
                      v-if="!medicalRecordAssociatedWithReservation"
                      class="date-time-input-field"
                      :date="dispReservation.date"
                      :startTime="dispReservation.startTime"
                      :endTime="dispReservation.endTime"
                      :styles="{ width: 'auto' }"
                      @input-data="setDateTime($event)"
                    />
                    <div v-else class="date-disp-field">
                      {{ formatDateTime }}
                    </div>
                  </div>
                  <div class="info-label-wrapper column">
                    <span class="info-label">予約列</span>
                    <span
                      v-if="!medicalRecordAssociatedWithReservation"
                      class="required column"
                      >必須</span
                    >
                  </div>
                  <div class="info-content-wrapper column">
                    <base-select-box
                      v-if="!medicalRecordAssociatedWithReservation"
                      :selectData="sortedReservationColumnsSelect"
                      :styles="{ width: '160px' }"
                      :value="dispReservation.reservationColumnId"
                      @input="selectReservationColumn"
                      data-test="column-select-box"
                    />
                    <div v-else class="column-disp-field">
                      {{
                        getReservationColumn(
                          dispReservation.reservationColumnId
                        ).name
                      }}
                    </div>
                  </div>
                </div>
                <div class="area purpose-care-staff-memo">
                  <div class="contents left">
                    <div class="row purpose">
                      <div class="info-label-wrapper left purpose">
                        <span class="info-label">来院目的</span>
                      </div>
                      <div class="info-content-wrapper left purpose">
                        <base-select-box
                          :selectData="reservationPurposesSelect"
                          :value="dispReservation.reservationPurposeId"
                          :styles="{ width: '300px' }"
                          v-model="dispReservation.reservationPurposeId"
                          data-test="purpose-select-box"
                        />
                      </div>
                    </div>
                    <div class="row care">
                      <div class="info-label-wrapper left">
                        <span class="info-label">診察内容</span>
                      </div>
                      <div class="info-content-wrapper left">
                        <validation-provider
                          class="validate-provider"
                          :rules="{ notSurrogatePair: true }"
                          v-slot="{ errors }"
                        >
                          <base-comb-box
                            class="comb-box"
                            :selectData="caresSelect"
                            :styles="{ width: '300px', fontSize: '13px' }"
                            v-model="dispReservation.care"
                          />
                          <div class="error">{{ errors[0] }}</div>
                        </validation-provider>
                      </div>
                    </div>
                    <div class="row staff">
                      <div class="info-label-wrapper left">
                        <span class="info-label">担当者</span>
                      </div>
                      <div class="info-content-wrapper left">
                        <base-select-box
                          :selectData="staffSelect"
                          :value="dispReservation.staffId"
                          :styles="{ width: '300px' }"
                          v-model="dispReservation.staffId"
                          data-test="staff-select-box"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="contents right memo">
                    <validation-provider
                      class="validate-provider"
                      :rules="{ notSurrogatePair: true }"
                      v-slot="{ errors }"
                    >
                      <base-multiple-lines-text-box
                        v-model="dispReservation.memo"
                        :placeholder="'メモ'"
                        :styles="{ width: '320px', height: '131px' }"
                      />
                      <div class="error">{{ errors[0] }}</div>
                    </validation-provider>
                  </div>
                </div>
                <div class="area owner-patient">
                  <div class="contents left">
                    <div class="row owner-show-id">
                      <div class="info-label-wrapper left">
                        <span class="info-label">飼主ID</span>
                      </div>
                      <div class="info-content-wrapper left">
                        <div
                          class="show-id-input-field owner"
                          v-if="
                            beforeReservation.ownerId === 0 ||
                              dispReservation.ownerId === 0
                          "
                        >
                          <div
                            class="show-id-input owner"
                            @click="searchOwners"
                            data-test="search-owners"
                            data-e2e="search-owners"
                          >
                            <div class="show-id-text">
                              {{ dispReservation.ownerShowId }}
                            </div>
                          </div>
                          <div class="show-id-clear owner">
                            <base-button-small-white
                              class="clear-button"
                              :styles="clearButtonStyles"
                              @click="ownerClear"
                              >クリア</base-button-small-white
                            >
                          </div>
                        </div>
                        <div class="show-id-disp-field owner" v-else>
                          <div
                            class="show-id-disp-text owner"
                            data-test="owner-show-id"
                          >
                            {{ dispReservation.ownerShowId }}
                          </div>
                          <div class="show-id-clear owner">
                            <base-button-small-white
                              v-if="
                                reservationRequestId === 0 &&
                                  !medicalRecordAssociatedWithReservation
                              "
                              class="clear-button"
                              :styles="clearButtonStyles"
                              @click="ownerClear"
                              >クリア</base-button-small-white
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row owner-name">
                      <div class="info-label-wrapper left owner-full-name">
                        <span class="info-label">飼主名</span>
                        <base-exclamation-mark
                          v-if="
                            dispReservation.ownerId === 0 &&
                              sameFullNameOwners.length > 0
                          "
                          :title="'同姓同名の飼主データがあります'"
                          :styles="{ marginLeft: '10px' }"
                        />
                      </div>
                      <div class="info-content-wrapper left">
                        <div
                          class="name-input-field"
                          v-if="dispReservation.ownerId === 0"
                        >
                          <div class="name-wrap left">
                            <div class="name-label">姓</div>
                            <div class="name-input">
                              <validation-provider
                                class="validate-provider"
                                :rules="{ nameRule: true }"
                                v-slot="{ errors }"
                              >
                                <base-text-box
                                  :maxlength="15"
                                  :styles="nameStyles"
                                  v-model="dispReservation.ownerLastName"
                                />
                                <div class="error">{{ errors[0] }}</div>
                              </validation-provider>
                            </div>
                          </div>
                          <div class="name-wrap right">
                            <span class="name-label">名</span>
                            <div class="name-input">
                              <validation-provider
                                class="validate-provider"
                                :rules="{ nameRule: true }"
                                v-slot="{ errors }"
                              >
                                <base-text-box
                                  :maxlength="15"
                                  :styles="nameStyles"
                                  v-model="dispReservation.ownerFirstName"
                                />
                                <div class="error">{{ errors[0] }}</div>
                              </validation-provider>
                            </div>
                          </div>
                        </div>
                        <div
                          v-else
                          class="name-disp-field"
                          data-test="owner-name"
                        >
                          {{
                            dispReservation.ownerLastName +
                              ' ' +
                              dispReservation.ownerFirstName
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="row owner-name-kana">
                      <div class="info-label-wrapper left">
                        <span class="info-label">飼主名カナ</span>
                      </div>
                      <div class="info-content-wrapper left">
                        <div
                          class="name-input-field"
                          v-if="dispReservation.ownerId === 0"
                        >
                          <div class="name-wrap left">
                            <div class="name-label">セイ</div>
                            <div class="name-input">
                              <validation-provider
                                class="validate-provider"
                                :rules="{ regexKana: true, nameRule: true }"
                                v-slot="{ errors }"
                              >
                                <base-text-box
                                  :maxlength="15"
                                  :styles="nameStyles"
                                  v-model="inputOwnerLastNameKana"
                                />
                                <div class="error">{{ errors[0] }}</div>
                              </validation-provider>
                            </div>
                          </div>
                          <div class="name-wrap right">
                            <div class="name-label">メイ</div>
                            <div class="name-input">
                              <validation-provider
                                class="validate-provider"
                                :rules="{ regexKana: true, nameRule: true }"
                                v-slot="{ errors }"
                              >
                                <base-text-box
                                  :maxlength="15"
                                  :styles="nameStyles"
                                  v-model="inputOwnerFirstNameKana"
                                />
                                <div class="error">{{ errors[0] }}</div>
                              </validation-provider>
                            </div>
                          </div>
                        </div>
                        <div
                          v-else
                          class="name-disp-field"
                          data-test="owner-name-kana"
                        >
                          {{
                            dispReservation.ownerLastNameKana +
                              ' ' +
                              dispReservation.ownerFirstNameKana
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="contents right">
                    <div class="row patient-show-id">
                      <div class="info-label-wrapper right">
                        <span class="info-label">患者ID</span>
                      </div>
                      <div class="info-content-wrapper right">
                        <div
                          class="show-id-input-field patient"
                          v-if="!medicalRecordAssociatedWithReservation"
                        >
                          <div
                            class="show-id-input patient"
                            @click="searchPatients"
                            data-test="search-patients"
                            data-e2e="search-patients"
                          >
                            <div class="show-id-text">
                              {{ dispReservation.patientShowId }}
                            </div>
                          </div>
                          <div class="show-id-clear patient">
                            <base-button-small-white
                              class="clear-button"
                              :styles="clearButtonStyles"
                              @click="patientClear"
                              >クリア</base-button-small-white
                            >
                          </div>
                        </div>
                        <div
                          class="show-id-disp-field patient"
                          data-test="patient-show-id"
                          v-else
                        >
                          {{ dispReservation.patientShowId }}
                        </div>
                      </div>
                    </div>
                    <div class="row patient-name">
                      <div class="info-label-wrapper right">
                        <span class="info-label">患者名</span>
                      </div>
                      <div class="info-content-wrapper right">
                        <template
                          v-if="!medicalRecordAssociatedWithReservation"
                        >
                          <validation-provider
                            v-if="dispReservation.ownerId === 0"
                            class="validate-provider"
                            :rules="{ notSurrogatePair: true }"
                            v-slot="{ errors }"
                          >
                            <base-text-box
                              :maxlength="30"
                              :styles="{ width: '160px', fontSize: '13px' }"
                              :disabled="dispReservation.patientId !== 0"
                              v-model="dispReservation.patientName"
                            />
                            <div class="error">{{ errors[0] }}</div>
                          </validation-provider>
                          <base-select-box
                            v-else
                            :value="dispReservation.patientId"
                            :styles="{ width: '160px' }"
                            :selectData="patientsSelect"
                            @input="selectPatient"
                            data-test="patient-select-box"
                          />
                        </template>
                        <template v-else>
                          <div
                            class="name-disp-field patient"
                            data-test="patient-name"
                          >
                            {{ dispReservation.patientName }}
                          </div>
                        </template>
                      </div>
                    </div>
                    <div class="button-area" v-if="karteFlg">
                      <base-button-border-orange
                        :styles="kartePaymentButtonStyles"
                        @click="clickKarteButton"
                        :disabled="
                          dispReservation.ownerId === 0 ||
                            dispReservation.patientId === 0
                        "
                        data-test="karte-button"
                        >カルテ</base-button-border-orange
                      >
                      <base-button-border-orange
                        :styles="kartePaymentButtonStyles"
                        class="payment-button"
                        :disabled="
                          !medicalPaymentAssociatedWithReservation ||
                            dispReservation.patientId === 0 ||
                            isInHospitalFlg
                        "
                        :alertFlg="
                          paymentType === '未会計' ||
                            paymentType === '未収金' ||
                            paymentType === '再会計'
                        "
                        @click="clickPaymentButton"
                        data-test="payment-button"
                        >会計</base-button-border-orange
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </popup>
        <announce-popup
          v-if="announce.show"
          :type="announce.type ? announce.type : 'alert'"
          :title="announce.title"
          :buttons="announce.buttons"
          @cancel="reservationOne"
          @decision="reservationAll"
          @close="closeAlert"
          :layerNumber="2"
          >{{ announce.content }}</announce-popup
        >
        <reservation-owners-search-popup
          v-if="search.show && search.ownerSearchFlg"
          :dispOwnerId="dispReservation.ownerId"
          @closeSearch="closeSearch"
          @selectOwner="selectSearchOwner"
        ></reservation-owners-search-popup>
        <reservation-patients-search-popup
          v-if="search.show && !search.ownerSearchFlg"
          :dispOwnerId="dispReservation.ownerId"
          :dispPatientId="dispReservation.patientId"
          :initialSearchPatients="initialSearchPatients"
          @closeSearch="closeSearch"
          @selectPatient="selectSearchPatient"
        ></reservation-patients-search-popup>
        <reservation-repeat-popup
          v-if="repeat.show"
          :reservation="dispReservation"
          :originalPopupPreventLeaveFlg="diffFlg"
          @cancel="closeRepeatPopup"
          @close="processAfterRepeat"
        ></reservation-repeat-popup>
        <announce-popup
          v-if="closeReservationPopupFlg"
          v-bind="unsavedAnnounce"
          @cancel="cancelCloseReservationPop"
          @decision="closeReservationPop"
          >{{ unsavedAnnounce.content }}</announce-popup
        >
        <announce-popup
          v-if="ownerShowAnnounce.show"
          v-bind="ownerShowAnnounce"
          @cancel="cancelCloseReservationPop"
          @decision="decision"
          >{{ ownerShowAnnounce.content }}</announce-popup
        >
        <announce-popup
          v-if="ownerNewAnnounce.show"
          v-bind="ownerNewAnnounce"
          @cancel="cancelCloseReservationPop"
          @decision="decision"
          >{{ ownerNewAnnounce.content }}</announce-popup
        >
        <announce-popup
          v-if="warningPopup.show"
          v-bind="warningPopup"
          @close="closeWarningPopup"
        />
        <announce-popup
          v-if="separatedReservationPopup.show"
          v-bind="separatedReservationPopup"
          @close="closeSeparatedReservationPopup"
        />
        <announce-popup
          v-if="emailConfirmationPopup.show"
          v-bind="emailConfirmationPopup"
          @cancel="closeEmailConfirmationPopup(false)"
          @decision="closeEmailConfirmationPopup(true)"
          >{{ emailConfirmationPopup.content }}</announce-popup
        >
        <same-name-owners-select-popup
          v-if="sameNameOwnersSelectPopupFlg"
          :sameFullNameOwners="sameFullNameOwners"
          :dispOwnerId="dispReservation.ownerId"
          :dispPatientId="dispReservation.patientId"
          :dispReservationId="dispReservation.id"
          @changeToExistingOwnerWithSameName="changeToExistingOwnerWithSameName"
          @close="sameNameOwnersSelectPopupFlg = false"
          @closeAndUpdateDuplicateCheckShowFlgToZero="
            closeAndUpdateDuplicateCheckShowFlgToZero
          "
        ></same-name-owners-select-popup>
      </validation-observer>
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import DateTimeInputForm from '@/components/parts/molecules/DateTimeInputForm'
import moment from 'moment'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import ReservationOwnersSearchPopup from '@/components/popups/ReservationOwnersSearchPopup'
import ReservationPatientsSearchPopup from '@/components/popups/ReservationPatientsSearchPopup'
import ReservationRepeatPopup from '@/components/popups/ReservationRepeatPopup'
import SameNameOwnersSelectPopup from '@/components/popups/SameNameOwnersSelectPopup'
import BaseMultipleLinesTextBox from '@/components/parts/atoms/BaseMultipleLinesTextBox'
import ReservationPopupEditHeader from '@/components/parts/molecules/ReservationPopupEditHeader'
import BaseTextBox from '@/components/parts/atoms/BaseTextBox'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import BaseCombBox from '@/components/parts/atoms/BaseCombBox'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import BaseExclamationMark from '@/components/parts/atoms/BaseExclamationMark'
import CheckPopupInputDifference from '@/components/mixins/CheckPopupInputDifference'
import _ from 'lodash'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { hiraToKata } from '@/utils/convert_string'
import { mapGetters } from 'vuex'
import { FocusTrap } from 'focus-trap-vue'
import { makePaymentDetail } from '@/utils/price_calculation'
import {
  patientRelatedParentStoreNamesReservationPlan,
  patientRelatedParentStoreNamesKartePlan,
  patientRelatedConvertNames
} from '@/utils/delete_owner_patients'

export default {
  name: 'ReservationPopup',
  components: {
    Popup,
    DateTimeInputForm,
    AnnouncePopup,
    ReservationOwnersSearchPopup,
    ReservationPatientsSearchPopup,
    ReservationRepeatPopup,
    SameNameOwnersSelectPopup,
    BaseMultipleLinesTextBox,
    ReservationPopupEditHeader,
    BaseTextBox,
    BaseSelectBox,
    BaseCombBox,
    BaseButtonSmallWhite,
    BaseButtonBorderOrange,
    BaseExclamationMark,
    ValidationProvider,
    ValidationObserver,
    FocusTrap
  },
  mixins: [CheckPopupInputDifference],
  props: {
    reservationRequestId: {
      type: Number,
      default: () => {
        return 0
      }
    },
    initialReservationProp: { type: Object, default: null }
  },
  data() {
    return {
      reservationId: 0,
      dispReservation: {},
      initialReservation: null,
      owner: {},
      patient: {},
      announce: {},
      waitFlg: false,
      search: {},
      nameStyles: { width: '110px', height: '33px', fontSize: '13px' },
      clearButtonStyles: { width: '80px' },
      kartePaymentButtonStyles: { width: '150px' },
      beforeReservation: {},
      closeReservationPopupFlg: false,
      pushToOwnerShowPageFlg: false,
      pushToOwnerNewPageFlg: false,
      pushToMedicalRecordPageFlg: false,
      pushToPaymentPageFlg: false,
      ownerShowAnnounce: {},
      ownerNewAnnounce: {},
      ownerEmail: '',
      initialOwnerEmail: '',
      reservations: this.$store.getters['reservations/getData'].filter(
        v => v.delFlg === 0 && v.cancelFlg === 0
      ),
      reservationRequests: this.$store.getters[
        'reservationRequests/getData'
      ].filter(v => v.reservationId === 0),
      unsavedAnnounce: {
        title: '確認',
        type: 'alert',
        buttons: ['キャンセル', '閉じる'],
        layerNumber: 2,
        content: '入力内容を保存せずに閉じようとしています。\nよろしいですか？'
      },
      ownerAnnounce: {
        show: false,
        title: '確認',
        type: 'alert',
        buttons: ['キャンセル', '登録して移動'],
        layerNumber: 2,
        content: '入力内容を登録して移動しますか？'
      },
      repeat: { show: false },
      noDataFlg: false,
      warningPopup: {
        show: false,
        layerNumber: 2,
        type: 'alert',
        title: '注意',
        leftAlignMessage: '',
        buttons: ['閉じる']
      },
      warningResolve: null,
      separatedReservationPopup: {
        show: false,
        layerNumber: 2,
        type: 'alert',
        title: '注意',
        leftAlignMessage:
          '飼主を変更しました。\n同時予約している他の予約は以前のままです。',
        buttons: ['閉じる']
      },
      separatedReservationResolve: null,
      emailConfirmationPopup: {
        show: false,
        layerNumber: 2,
        type: 'alert',
        title: 'メール確認',
        content: '',
        buttons: ['いいえ', 'はい']
      },
      emailConfirmationResolve: null,
      mailFlgs: {
        new: true,
        change: true,
        cancel: true
      },
      sameNameOwnersSelectPopupFlg: false,
      netReservationSameNameNewOwnerId: 0,
      netReservationSameNameNewOwnerPatientId: 0
    }
  },
  computed: {
    ...mapGetters({
      owners: 'owners/getData',
      getReservationColumn: 'reservationColumns/getDataById',
      reservationColumnsSelect: 'reservationColumns/selectData',
      getStaffReservationColumn: 'staffReservationColumns/getDataByColumnId',
      reservationPurposesSelect: 'reservationPurposes/selectDataZero',
      caresSelect: 'cares/selectDataZero',
      staffSelect: 'staffs/selectDataZero',
      reservationSetting: 'reservationSetting/getData',
      getReservation: 'reservations/getDataById',
      lookOnlyFlg: 'auth/lookOnlyFlg',
      karteFlg: 'auth/karteFlg',
      getMedicalPaymentsByRecordOriginalId:
        'medicalPayments/getDataByMedicalRecordOriginalId',
      getMedicalPaymentHistory: 'medicalPayments/getDataByOriginalIdIncludeDel',
      getMedicalRecordByOriginalId: 'medicalRecords/getDataByOriginalId',
      getPaymentsByMedicalPaymentId: 'payments/getDataByMedicalPaymentId',
      waitingStatuses: 'waitingStatuses/getData',
      getWaitingStatus: 'waitingStatuses/getDataById'
    }),
    medicalRecordAssociatedWithReservation() {
      if (this.karteFlg) {
        const medicalRecord = this.getMedicalRecordByOriginalId(
          this.beforeReservation.medicalRecordOriginalId
        )
        return medicalRecord ? medicalRecord : null
      } else {
        return null
      }
    },
    medicalPaymentAssociatedWithReservation() {
      if (this.karteFlg && this.medicalRecordAssociatedWithReservation) {
        const medicalPaymentData = this.getMedicalPaymentsByRecordOriginalId(
          this.medicalRecordAssociatedWithReservation.originalId
        )
        return medicalPaymentData ? medicalPaymentData[0] : null
      } else {
        return null
      }
    },
    isInHospitalFlg() {
      if (this.karteFlg && this.medicalPaymentAssociatedWithReservation) {
        const medicalPayment = this.medicalPaymentAssociatedWithReservation
        return Boolean(
          (medicalPayment.startHospitalizationFlg === 1 ||
            medicalPayment.inHospitalFlg === 1) &&
            medicalPayment.middleCalculateFlg === 0 &&
            medicalPayment.endHospitalizationFlg === 0
        )
      } else {
        return false
      }
    },
    formatDateTime() {
      return this.karteFlg
        ? moment(this.dispReservation.date, 'YYYYMMDD').format(
            'YYYY年MM月DD日（dd）'
          ) +
            ' ' +
            moment(this.dispReservation.startTime, 'HHmm').format('HH:mm') +
            '～' +
            moment(this.dispReservation.endTime, 'HHmm').format('HH:mm')
        : null
    },
    sortedReservationColumnsSelect() {
      return this.reservationColumnsSelect
        .map(v => {
          const staffReservationColumn = this.getStaffReservationColumn(v.id)
          return { ...v, order: staffReservationColumn.order }
        })
        .sort((a, b) => (a.order < b.order ? -1 : 1))
    },
    ownersGroupByFullName() {
      return this.dispReservation.duplicateCheckShowFlg === 1 ||
        this.dispReservation.ownerId === 0
        ? _.groupBy(this.owners, owner =>
            (owner.lastName + '_' + owner.firstName).toLowerCase()
          )
        : {}
    },
    sameFullNameOwners() {
      const ownerFullName = (
        this.dispReservation.ownerLastName +
        '_' +
        this.dispReservation.ownerFirstName
      ).toLowerCase()
      return this.ownersGroupByFullName[ownerFullName] || []
    },
    patientsSelect() {
      const selectData = this.$store.getters['patients/getData'].filter(
        v => v.ownerId === this.dispReservation.ownerId && v.deathFlg === 0
      )
      if (this.sameGroupReservationRequests.length >= 2) {
        const removePatientIds = this.sameGroupReservationRequests
          .filter(v => v.patientId !== this.beforeReservation.patientId)
          .map(v => v.patientId)
        return selectData.filter(v => !removePatientIds.includes(v.id))
      } else if (this.sameGroupReservations.length >= 2) {
        const removePatientIds = this.sameGroupReservations
          .filter(v => v.patientId !== this.beforeReservation.patientId)
          .map(v => v.patientId)
        return selectData.filter(v => !removePatientIds.includes(v.id))
      } else {
        return [{ id: 0, name: '', showId: '', ownerId: 0 }].concat(selectData)
      }
    },
    inputOwnerLastNameKana: {
      get() {
        return this.dispReservation.ownerLastNameKana
      },
      set(value) {
        this.$set(this.dispReservation, 'ownerLastNameKana', hiraToKata(value))
      }
    },
    inputOwnerFirstNameKana: {
      get() {
        return this.dispReservation.ownerFirstNameKana
      },
      set(value) {
        this.$set(this.dispReservation, 'ownerFirstNameKana', hiraToKata(value))
      }
    },
    diffFlg() {
      return !_.isEqual(this.beforeReservation, this.dispReservation)
    },
    sameGroupReservations() {
      return this.reservations.filter(
        v => v.groupKey !== '' && v.groupKey === this.dispReservation.groupKey
      )
    },
    sameGroupReservationRequests() {
      return this.reservationRequests.filter(
        v =>
          v.groupKey !== '' &&
          v.groupKey === this.dispReservation.groupKey &&
          v.reservationId === 0
      )
    },
    groupedReservationOwnerDiffFlg() {
      return (
        this.initialReservation?.ownerId !== this.dispReservation.ownerId &&
        (this.sameGroupReservations.length >= 2 ||
          this.sameGroupReservationRequests.length >= 2)
      )
    },
    initialSearchPatients() {
      return this.dispReservation.ownerId === 0
        ? this.$store.getters['patients/getData']
        : this.patientsSelect.filter(v => v.id !== 0)
    },
    paymentType() {
      if (
        this.karteFlg &&
        this.medicalPaymentAssociatedWithReservation &&
        !this.isInHospitalFlg
      ) {
        const { type } = makePaymentDetail(
          this.medicalPaymentAssociatedWithReservation,
          this.getMedicalPaymentHistory,
          this.getMedicalRecordByOriginalId,
          this.getPaymentsByMedicalPaymentId
        )
        return type
      } else return undefined
    },
    mailSettings() {
      const {
        reservationMailAuto,
        cancelMailAuto,
        changeMailAuto
      } = this.reservationSetting
      return {
        new: reservationMailAuto,
        cancel: cancelMailAuto,
        change: changeMailAuto
      }
    }
  },
  async created() {
    this.initialReservation =
      this.initialReservationProp ||
      this.$store.getters['timeTable/get'].initialReservation
    this.search = { show: false, title: '飼主検索' }
    this.announce = { show: false }
    if (this.initialReservation) {
      if (this.initialReservation.id) {
        this.reservationId = this.initialReservation.id
      } else if (
        this.initialReservation.reservationColumnId &&
        !this.initialReservation.staffId
      ) {
        const reservationColumn = this.getReservationColumn(
          this.initialReservation.reservationColumnId
        )
        this.initialReservation.staffId = reservationColumn.staffId
      }
    }
    let reservation =
      this.reservationId === 0
        ? {
            id: 0,
            clinicId: this.$store.getters['clinic/getData'].id,
            date: this.$store.getters['timeTable/get'].date,
            startTime: this.newReservationTime()[0],
            endTime: this.newReservationTime()[1],
            reservationColumnId: 0,
            staffId: 0,
            reservationPurposeId: 0,
            waitingStatusId: this.waitingStatuses.find(v => v.initialValueFlg)
              .id,
            ownerId: 0,
            patientId: 0,
            ownerLastName: '',
            ownerFirstName: '',
            ownerLastNameKana: '',
            ownerFirstNameKana: '',
            patientName: '',
            groupKey:
              this.reservationRequestId !== 0
                ? this.$store.getters['reservationRequests/getDataById'](
                    this.reservationRequestId
                  ).groupKey
                : '',
            care: '',
            memo: '',
            cancelFlg: 0,
            duplicateCheckShowFlg: 0
          }
        : this.getReservation(this.reservationId)
    if (this.initialReservation !== null) {
      reservation = {
        ...reservation,
        ...this.initialReservation
      }
    }
    this.dispReservation = { ...reservation }
    if (this.dispReservation.ownerId !== 0) {
      this.setOwner(this.dispReservation.ownerId)
      this.initialOwnerEmail = this.ownerEmail
    } else {
      this.dispReservation.ownerShowId = ''
    }
    this.selectPatient(this.dispReservation.patientId, reservation.patientName)
    this.$store.dispatch('timeTable/deleteInitials')
    this.beforeReservation = _.cloneDeep(this.dispReservation)
    this.setBeforeDragReservation()
    const existsSameNameOwnerWhenOpenNetFirstReservation =
      this.dispReservation.duplicateCheckShowFlg === 1 &&
      this.dispReservation.medicalRecordOriginalId === 0 &&
      this.sameFullNameOwners.length >= 2
    if (
      existsSameNameOwnerWhenOpenNetFirstReservation &&
      !(await this.existsPatientRelatedData())
    ) {
      this.sameNameOwnersSelectPopupFlg = true
    }
  },
  destroyed() {
    this.$route.params.fromPage = false
    // ↓予約終了時間のドラッグによる変更で保管していた一時的な予約終了時間の値を消す処理
    const moveEndTimeObj = this.$store.getters['timeTable/get'].moveEndTimeObj
    if (
      moveEndTimeObj.afterEndTime !== '' &&
      moveEndTimeObj.reservation.id !== 0
    ) {
      this.$store.dispatch('timeTable/deleteMoveEndTimeOfReservation')
    }
  },
  methods: {
    setBeforeDragReservation() {
      const stateReservation = this.getReservation(this.beforeReservation.id)
      if (stateReservation) {
        if (
          stateReservation.startTime !== this.beforeReservation.startTime ||
          stateReservation.endTime !== this.beforeReservation.endTime ||
          stateReservation.reservationColumnId !==
            this.beforeReservation.reservationColumnId
        ) {
          this.beforeReservation = {
            ...this.beforeReservation,
            startTime: stateReservation.startTime,
            endTime: stateReservation.endTime,
            reservationColumnId: stateReservation.reservationColumnId
          }
        }
      }
    },
    showOwner() {
      if (
        this.diffFlg === true ||
        this.$route.params.fromPage === 'owner' ||
        this.$route.params.fromPage === 'patient' ||
        this.$route.params.fromPage === 'payment-show' ||
        this.reservationRequestId !== 0
      ) {
        this.pushToOwnerShowPageFlg = true
        this.ownerShowAnnounce = { ...this.ownerAnnounce, show: true }
      } else {
        this.$store.dispatch('timeTable/deletePopup')
        this.$store.dispatch(
          'timeTable/setReservationId',
          this.dispReservation.id
        )
        this.$router.push({
          name: `owner`,
          params: { id: this.dispReservation.ownerId }
        })
      }
    },
    cancelCloseReservationPop() {
      this.closeReservationPopupFlg = false
      this.announce = {}
      this.ownerShowAnnounce = {}
      this.ownerNewAnnounce = {}
      this.pushToOwnerShowPageFlg = false
      this.pushToOwnerNewPageFlg = false
      this.pushToMedicalRecordPageFlg = false
      this.pushToPaymentPageFlg = false
    },
    async closeReservationPop() {
      this.$emit('close', { action: 'close', sendMailFlg: false })
    },
    newOwner() {
      if (this.diffFlg === true) {
        this.pushToOwnerNewPageFlg = true
        this.ownerNewAnnounce = { ...this.ownerAnnounce, show: true }
      } else {
        this.pushToOwnerNew()
      }
    },
    changeWaitingStatus(waitingStatusId) {
      this.dispReservation.waitingStatusId = waitingStatusId
    },
    setDateTime({ field, value }) {
      if (value) {
        if (field === 'date') {
          this.$store.dispatch('timeTable/changeDate', value)
          this.dispReservation.date = value
        }
        if (field === 'startTime') {
          this.dispReservation[field] = value
        }
        if (field === 'endTime') {
          this.dispReservation[field] = value
        }
      }
    },
    selectPatient(id, patientName) {
      this.dispReservation.patientId = id
      const patient =
        id === 0
          ? { id: 0, ownerId: 0, name: patientName, showId: '' }
          : this.$store.getters['patients/getDataById'](id)
      this.dispReservation.patientName = patient.name
      this.dispReservation.patientShowId = patient.showId
    },
    patientClear() {
      this.dispReservation.patientName = ''
      this.dispReservation.patientId = 0
      this.dispReservation.patientShowId = ''
    },
    ownerClear() {
      this.dispReservation = {
        ...this.dispReservation,
        ownerId: 0,
        ownerShowId: '',
        ownerLastName: '',
        ownerFirstName: '',
        ownerLastNameKana: '',
        ownerFirstNameKana: ''
      }
      this.ownerEmail = ''
      this.patientClear()
    },
    newReservationTime() {
      const startTime = this.initialReservation?.startTime
        ? this.initialReservation.startTime
        : moment()
            .add(59, 'minutes')
            .format('HH') + '00'
      const endTime = this.initialReservation?.endTime
        ? this.initialReservation.endTime
        : moment()
            .add(59, 'minutes')
            .format('HH') + this.reservationSetting.reservationTimeUnit
      return [startTime, endTime]
    },
    setOwner(ownerId) {
      if (ownerId !== this.dispReservation.ownerId) {
        this.patientClear()
      }
      const owner =
        ownerId === 0
          ? {
              showId: '',
              lastName: '',
              firstName: '',
              lastNameKana: '',
              firstNameKana: ''
            }
          : this.$store.getters['owners/getDataById'](ownerId)
      this.search = { show: false }
      this.dispReservation.ownerId = ownerId
      this.dispReservation.ownerShowId = owner.showId
      this.dispReservation.ownerLastName = owner.lastName
      this.dispReservation.ownerFirstName = owner.firstName
      this.dispReservation.ownerLastNameKana = owner.lastNameKana
      this.dispReservation.ownerFirstNameKana = owner.firstNameKana
      this.ownerEmail = owner.email
    },
    selectSearchOwner(ownerId) {
      this.setOwner(ownerId)
      this.dispReservation.patientId = 0
      this.dispReservation.patientShowId = ''
      this.dispReservation.patientName = ''
    },
    selectSearchPatient(patientId) {
      const patient = this.$store.getters['patients/getDataById'](patientId)
      this.selectSearchOwner(patient.ownerId)
      this.dispReservation.patientId = patient.id
      this.dispReservation.patientShowId = patient.showId
      this.dispReservation.patientName = patient.name
    },
    closeSearch() {
      this.search = { show: false }
    },
    searchPatients() {
      this.search = { show: true, ownerSearchFlg: false }
    },
    searchOwners() {
      this.search = { show: true, ownerSearchFlg: true }
    },
    selectReservationColumn(reservationColumnId) {
      this.dispReservation.reservationColumnId = reservationColumnId
      if (this.dispReservation.id === 0 && !this.dispReservation.staffId) {
        const reservationColumn = this.getReservationColumn(reservationColumnId)
        this.dispReservation.staffId = reservationColumn.staffId
      }
    },
    cancel() {
      this.diffFlg === true
        ? (this.closeReservationPopupFlg = true)
        : this.$emit('close', { action: 'close', sendMailFlg: false })
    },
    async decision() {
      this.waitFlg = true
      this.ownerShowAnnounce = {}
      this.ownerNewAnnounce = {}
      const changeableReservations = this.sameGroupReservations.filter(
        v => v.medicalRecordOriginalId === 0
      )
      if (this.groupedReservationOwnerDiffFlg) {
        await this.reservationOne()
      } else if (this.sameGroupReservationRequests.length >= 2) {
        // 複数の同時予約申請から予約作成
        this.announce = {
          show: true,
          title: '注意',
          content: '同時申請されている他の予約申請も登録を行いますか？',
          buttons: ['他の予約申請は登録しない', '他の予約申請も登録する']
        }
      } else if (
        this.initialReservation?.medicalRecordOriginalId === 0 &&
        changeableReservations.length >= 2
      ) {
        // カルテに紐づいていない複数の同時予約
        const tgtProperties = [
          'waitingStatusId',
          'date',
          'startTime',
          'endTime',
          'reservationColumnId',
          'reservationPurposeId',
          'care',
          'staffId',
          'memo'
        ]
        if (
          changeableReservations.every(v =>
            _.isEqual(
              _.pick(v, tgtProperties),
              _.pick(this.dispReservation, tgtProperties)
            )
          )
        ) {
          // 患者以外の項目で変更が"ない"場合、同時予約を全て変更
          await this.reservationAll()
        } else {
          // 患者以外の項目で変更が"ある"場合、同時に変更するか確認
          this.announce = {
            show: true,
            title: '注意',
            content:
              '同時予約されている他の予約にも同様の変更を行いますか？\n※カルテのない予約のみ変更できます。',
            buttons: ['他の予約は変更しない', '他の予約も変更する']
          }
          // 他の予約は変更しない -> reservationOne
          // 他の予約も変更する -> reservationAll
        }
      } else {
        await this.reservationOne()
      }
      this.waitFlg = false
    },
    closeAlert() {
      this.announce = { show: false }
      if (this.noDataFlg) this.$store.dispatch('timeTable/deletePopup')
    },
    deleteReservation(event) {
      this.$emit('close', {
        action: event.action,
        sendMailFlg: event.sendMailFlg
      })
    },
    async reservationAll() {
      await this.setMailFlgs()
      const reservation = this.dispReservation
      const res =
        this.reservationId === 0
          ? await this.$store.dispatch('reservations/create', {
              reservation,
              reservationRequestId: this.reservationRequestId,
              mailFlgs: this.mailFlgs
            })
          : await this.$store.dispatch('reservations/update', {
              reservation,
              mailFlgs: this.mailFlgs
            })
      await this.processAfterRes(res)
    },
    async reservationOne() {
      const hasChangedDateTimeWhenChangeToSameNameOwner =
        this.netReservationSameNameNewOwnerId > 0 && this.hasChangedDateTime()
      if (
        this.netReservationSameNameNewOwnerId === 0 ||
        hasChangedDateTimeWhenChangeToSameNameOwner
      ) {
        await this.setMailFlgs()
      }
      // 他の同時予約から切り離す場合 groupKey を空にする
      const reservation =
        this.sameGroupReservations.length >= 2 ||
        this.sameGroupReservationRequests.length >= 2
          ? { ...this.dispReservation, groupKey: '' }
          : this.dispReservation
      const res =
        this.reservationId === 0
          ? await this.$store.dispatch('reservations/create', {
              reservation,
              reservationRequestId: this.reservationRequestId,
              mailFlgs: this.mailFlgs
            })
          : await this.$store.dispatch('reservations/update', {
              reservation,
              mailFlgs: this.mailFlgs,
              netReservationSameNameNewOwnerInfo: {
                ownerId: this.netReservationSameNameNewOwnerId,
                patientId: this.netReservationSameNameNewOwnerPatientId
              }
            })
      await this.processAfterRes(res)
    },
    pushToOwnerShow(reservationId) {
      this.$store.dispatch('timeTable/deletePopup')
      this.$store.dispatch('timeTable/setReservationId', reservationId)
      this.$router.push({
        name: `owner`,
        params: { id: this.dispReservation.ownerId }
      })
    },
    pushToOwnerNew() {
      this.$store.dispatch('timeTable/deletePopup')
      this.$store.dispatch(
        'timeTable/setReservationId',
        this.dispReservation.id
      )
      const moveEndTimeObj = this.$store.getters['timeTable/get'].moveEndTimeObj
      const scrollInfo = {
        startTime: this.dispReservation.startTime,
        reservationColumnId: this.dispReservation.reservationColumnId
      }
      this.$router.push({
        name: `owner-new`,
        params: {
          reservation: this.dispReservation,
          moveEndTimeObj,
          scrollInfo
        }
      })
    },
    async processAfterRes(res) {
      if (res.result === 'success') {
        if (this.groupedReservationOwnerDiffFlg) {
          await this.openSeparatedReservationPopup()
        }
        if (res.insuranceWarnings.length > 0) {
          await this.openWarningPopup(res.insuranceWarnings)
        }
        this.$store.dispatch('petorelu/popupOkLeave')
        this.reservationId === 0
          ? this.$emit('close', {
              action: 'create',
              sendMailFlg: res.sendMailFlg,
              pushToAnotherPageFlg:
                this.pushToOwnerShowPageFlg ||
                this.pushToOwnerNewPageFlg ||
                this.pushToMedicalRecordPageFlg ||
                this.pushToPaymentPageFlg
            })
          : this.$emit('close', {
              action: 'update',
              sendMailFlg: res.sendMailFlg,
              isNewOwnerDeleted: res.isNewOwnerDeleted
            })
        if (this.pushToOwnerShowPageFlg) {
          this.pushToOwnerShow(res.reservationId)
        }
        if (this.pushToOwnerNewPageFlg) {
          this.pushToOwnerNew()
        }
        if (this.pushToMedicalRecordPageFlg) {
          this.pushToMedicalRecordNewEdit(res.reservationId)
        }
        if (this.pushToPaymentPageFlg) {
          this.pushToPaymentNewShow()
        }
        this.$store.dispatch('timeTable/setHighlightedReservation', {
          ids: res.reservationIds,
          type: 'createUpdate'
        })
      } else {
        if (
          this.netReservationSameNameNewOwnerId > 0 &&
          this.netReservationSameNameNewOwnerPatientId > 0
        ) {
          // 変更前の状態に戻す
          this.selectSearchPatient(this.netReservationSameNameNewOwnerPatientId)
        }
        this.netReservationSameNameNewOwnerId = 0
        this.netReservationSameNameNewOwnerPatientId = 0
        this.pushToOwnerShowPageFlg = false
        this.pushToOwnerNewPageFlg = false
        this.pushToMedicalRecordPageFlg = false
        this.pushToPaymentPageFlg = false
        const method = this.reservationId === 0 ? '登録' : '編集'
        this.announce.show = true
        this.announce.title = `${method}失敗`
        this.announce.buttons = ['閉じる']
        this.announce.content =
          res === 'no reservationColumn' &&
          this.dispReservation.reservationColumnId === 0
            ? '予約列を選択してください'
            : res === 'no reservationColumn'
            ? `他のユーザーが予約列データを削除した為、${method}に失敗しました`
            : res === 'no owner'
            ? `他のユーザーが飼主データを削除した為、${method}に失敗しました`
            : res === 'no patient'
            ? `他のユーザーが患者データを削除した為、${method}に失敗しました`
            : res === 'no reservationPurpose'
            ? `他のユーザーが来院目的データを削除した為、${method}に失敗しました`
            : res === 'no staff'
            ? `他のユーザーがスタッフデータを削除した為、${method}に失敗しました`
            : res === 'no reservationRequest'
            ? `他のユーザーが予約申請データを削除した為、登録に失敗しました`
            : res === 'no reservation' || res === 'no data in clinic'
            ? '編集中の予約は削除またはキャンセルされている為、編集に失敗しました'
            : res === 'currently used'
            ? 'カルテがある為、編集に失敗しました'
            : res === 'no new patient'
            ? `他のユーザーが新規患者データを削除した為、${method}に失敗しました`
            : `${method}に失敗しました`
        if (
          res === 'no reservation' ||
          res === 'no data in clinic' ||
          res === 'currently used'
        ) {
          this.noDataFlg = true
        }
      }
    },
    async openWarningPopup(insuranceWarnings) {
      const base =
        '予約日時が下記の患者に設定されている窓口精算期間または保険期間を過ぎています。\n保険証の確認をお願いします。'
      const texts = [base]
      insuranceWarnings.forEach(v => {
        const patientText = `患者ID:${v.patientShowId}, 患者名:${v.patientName}`
        const insuranceText = `保険:${v.insuranceName}, ${v.periodName}: ${v.periodStart} ～ ${v.periodEnd}`
        texts.push(`${patientText}\n${insuranceText}`)
      })
      this.warningPopup.leftAlignMessage = `${texts.join('\n\n')}`
      this.warningPopup.show = true
      await new Promise(resolve => (this.warningResolve = resolve))
    },
    closeWarningPopup() {
      this.warningPopup.show = false
      this.warningPopup.leftAlignMessage = ''
      this.warningResolve()
      this.warningResolve = null
    },
    async openSeparatedReservationPopup() {
      this.separatedReservationPopup.show = true
      await new Promise(resolve => (this.separatedReservationResolve = resolve))
    },
    closeSeparatedReservationPopup() {
      this.separatedReservationPopup.show = false
      this.separatedReservationResolve()
      this.separatedReservationResolve = null
    },
    hasChangedDateTime() {
      return ['startTime', 'date'].some(
        v => this.beforeReservation[v] !== this.dispReservation[v]
      )
    },
    async setMailFlgs() {
      const mailTypes = ['new', 'change', 'cancel']
      mailTypes.forEach(v => {
        if (this.mailSettings[v] === 'auto') {
          this.mailFlgs[v] = true
        } else if (this.mailSettings[v] === 'off') {
          this.mailFlgs[v] = false
        }
      })
      const mailCheck = Object.values(this.mailSettings).some(
        v => v === 'check'
      )
      const upcomingReservation =
        this.dispReservation.date + this.dispReservation.startTime >=
        moment().format('YYYYMMDDHHmm')
      if (mailCheck && upcomingReservation) {
        const reservationType =
          this.reservationId === 0
            ? 'new'
            : this.beforeReservation.ownerId !== this.dispReservation.ownerId
            ? 'ownerChange'
            : 'update'
        // 飼い主変更ないし、普通の予約作成または変更
        const reservationMailCondition =
          reservationType === 'new' &&
          this.mailSettings.new === 'check' &&
          this.ownerEmail
        const changeMailCondition =
          reservationType === 'update' &&
          this.mailSettings.change === 'check' &&
          this.hasChangedDateTime() &&
          this.ownerEmail
        if (reservationMailCondition || changeMailCondition) {
          this.emailConfirmationPopup.content = `予約を${
            reservationMailCondition ? '作成' : '変更'
          }しました。飼主様に確認メールを送信しますか？`
          await this.openEmailConfirmationPopup()
        } else if (
          reservationType === 'ownerChange' &&
          (this.ownerEmail || this.initialOwnerEmail)
        ) {
          // 飼い主変更の場合
          const newOwnerNewMailCondition =
            this.mailSettings.new === 'check' && this.ownerEmail
          const oldOwnerCancelMailCondition =
            this.mailSettings.cancel === 'check' &&
            this.sameGroupReservations.length < 2 &&
            this.initialOwnerEmail
          const oldOwnerChangeMailCondition =
            this.mailSettings.change === 'check' &&
            this.sameGroupReservations.length >= 2 &&
            this.initialOwnerEmail
          if (
            newOwnerNewMailCondition ||
            oldOwnerCancelMailCondition ||
            oldOwnerChangeMailCondition
          ) {
            this.emailConfirmationPopup.content =
              '予約を変更しました。 飼主様に確認メールを送信しますか？'
            await this.openEmailConfirmationPopup()
          }
        }
      }
    },
    async openEmailConfirmationPopup() {
      this.emailConfirmationPopup.show = true
      await new Promise(resolve => (this.emailConfirmationResolve = resolve))
    },
    closeEmailConfirmationPopup(flg) {
      const mailKeys = Object.keys(this.mailSettings)
      mailKeys.forEach(v => {
        if (this.mailSettings[v] === 'check') this.mailFlgs[v] = flg
      })
      this.emailConfirmationPopup.show = false
      this.emailConfirmationResolve()
      this.emailConfirmationResolve = null
    },
    openRepeatPopup() {
      this.repeat = { show: true }
    },
    closeRepeatPopup() {
      this.repeat = { show: false }
    },
    processAfterRepeat(event) {
      if (event.result === 'success') {
        this.$emit('close', {
          action: 'create-repeat',
          sendMailFlg: event.sendMailFlg,
          pushToAnotherPageFlg: false
        })
      }
    },
    clickKarteButton() {
      if (
        this.diffFlg === true ||
        this.$route.params.fromPage === 'owner' ||
        this.$route.params.fromPage === 'patient' ||
        this.reservationRequestId !== 0 ||
        this.reservationId === 0
      ) {
        this.pushToMedicalRecordPageFlg = true
        this.ownerShowAnnounce = { ...this.ownerAnnounce, show: true }
      } else {
        this.pushToMedicalRecordNewEdit(this.reservationId)
      }
    },
    pushToMedicalRecordNewEdit(reservationId) {
      const reservation = this.getReservation(reservationId)
      const medicalRecord = this.getMedicalRecordByOriginalId(
        reservation.medicalRecordOriginalId
      )
      //↓params:reservationのデータを送るため、$router.pushは名前付きルートの書き方で統一しております
      if (medicalRecord) {
        this.$router.push({
          name: 'medical-record-edit',
          params: {
            ownerId: reservation.ownerId,
            patientId: reservation.patientId,
            originalId: medicalRecord.originalId
          }
        })
      } else {
        this.$router.push({
          name: 'medical-record-new',
          params: {
            ownerId: reservation.ownerId,
            patientId: reservation.patientId,
            reservation: reservation
          }
        })
      }
      this.$store.dispatch('timeTable/deletePopup')
    },
    clickPaymentButton() {
      if (
        this.diffFlg === true ||
        this.$route.params.fromPage === 'owner' ||
        this.$route.params.fromPage === 'patient' ||
        this.reservationRequestId !== 0 ||
        this.reservationId === 0
      ) {
        this.pushToPaymentPageFlg = true
        this.ownerShowAnnounce = { ...this.ownerAnnounce, show: true }
      } else {
        this.pushToPaymentNewShow()
      }
    },
    pushToPaymentNewShow() {
      const ownerId = this.beforeReservation.ownerId
      const patientId = this.beforeReservation.patientId
      const medicalPaymentOriginalId = this
        .medicalPaymentAssociatedWithReservation.originalId
      this.paymentType === '未会計'
        ? this.$router.push(
            `/main/karte/owners/${ownerId}/patients/${patientId}/payments/${medicalPaymentOriginalId}/new`
          )
        : this.$router.push(
            `/main/karte/owners/${ownerId}/patients/${patientId}/payments/${medicalPaymentOriginalId}/show`
          )
      this.$store.dispatch('timeTable/deletePopup')
    },
    async tryGetDataForCheckDuplicateOwner() {
      const isAcquiredPatient = this.$store.getters[
        'patients/getAcquiredPatientIdsForCheckDuplicateOwner'
      ].has(this.dispReservation.patientId)
      return !this.karteFlg || isAcquiredPatient
        ? true
        : await this.$store.dispatch(
            'patients/getDataForCheckDuplicateOwner',
            this.dispReservation.patientId
          )
    },
    async existsPatientRelatedData() {
      const result = await this.tryGetDataForCheckDuplicateOwner()
      if (!result) return true // データ取得に失敗した場合はtrueを返して同姓同名の飼主選択ポップアップを表示しないようにする
      const patientRelatedStoreNames = this.karteFlg
        ? patientRelatedParentStoreNamesKartePlan
        : patientRelatedParentStoreNamesReservationPlan
      return patientRelatedStoreNames.some(storeName => {
        if (
          process.env.NODE_ENV !== 'production' &&
          process.env.NODE_ENV !== 'test' &&
          (storeName === 'anicomCIdChecks' || storeName === 'ipetChecks')
        ) {
          // 承認番号のデータは開発環境・gaeテスト環境では同じデータベースのデータを使用しているため、
          // 他の人が作成したデータを取得してしまう。(ipetChecksもいずれそうなる可能性が高いため入れた)
          // 同姓同名の飼主選択ポップアップが開かなくなるのを防ぐため、上記の環境では承認番号のデータはチェックの対象に入れていない
          return false
        }
        const data = this.$store.getters[`${storeName}/getDataByPatientId`](
          this.dispReservation.patientId
        )
        return data && data.length > 0
      })
    },
    checkPatientRelatedData() {
      const patientRelatedStoreNames = this.karteFlg
        ? patientRelatedParentStoreNamesKartePlan
        : patientRelatedParentStoreNamesReservationPlan
      const patientRelatedDataNames = []
      patientRelatedStoreNames.forEach(storeName => {
        if (
          process.env.NODE_ENV !== 'production' &&
          process.env.NODE_ENV !== 'test' &&
          (storeName === 'anicomCIdChecks' || storeName === 'ipetChecks')
        ) {
          // 開発環境・gaeテスト環境では上記の2つのデータはチェックしない
          return
        }
        const data = this.$store.getters[`${storeName}/getDataByPatientId`](
          this.beforeReservation.patientId
        )
        const convertedName = patientRelatedConvertNames[storeName]
        if (data && data.length > 0) {
          patientRelatedDataNames.push(convertedName)
        }
      })
      return patientRelatedDataNames
    },
    setOwnerAndPatient(ownerId, patientId) {
      this.ownerClear()
      this.selectSearchOwner(ownerId)
      const patient = this.$store.getters['patients/getDataById'](patientId)
      this.dispReservation.patientId = patient.id
      this.dispReservation.patientShowId = patient.showId
      this.dispReservation.patientName = patient.name
      this.netReservationSameNameNewOwnerId = this.beforeReservation.ownerId
      this.netReservationSameNameNewOwnerPatientId = this.beforeReservation.patientId
    },
    changeToExistingOwnerWithSameName({ ownerId, patientId }) {
      const patientRelatedDataNames = this.checkPatientRelatedData()
      if (patientRelatedDataNames.length > 0) {
        const names = patientRelatedDataNames.join('、')
        this.announce.show = true
        this.announce.title = '変更失敗'
        this.announce.buttons = ['閉じる']
        this.announce.content = `新規患者に${names}のデータがあるため、予約変更できません。`
        this.sameNameOwnersSelectPopupFlg = false
        return
      }
      this.setOwnerAndPatient(ownerId, patientId)
      this.sameNameOwnersSelectPopupFlg = false
      this.decision()
    },
    closeAndUpdateDuplicateCheckShowFlgToZero() {
      this.sameNameOwnersSelectPopupFlg = false
      // ↓値が1のまま登録ボタンを押すとreservationのduplicateCheckShowFlgが1に再び更新されてしまうため、0に変更している
      this.beforeReservation.duplicateCheckShowFlg = 0
      this.dispReservation.duplicateCheckShowFlg = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-content {
  width: 760px;
  > .reservation-body {
    margin-top: 16px;
    text-align: left;
    padding: 0 15px;
    > .row {
      display: flex;
      align-items: center;
      height: 33px;
      > .info-label-wrapper {
        font-size: 15px;
        > .required {
          margin-left: 5px;
          font-size: 13px;
          color: #{$tomato};
        }
        &.date {
          width: 90px;
        }
        &.column {
          margin-left: -5px;
          width: 86px;
        }
      }
      > .info-content-wrapper {
        &.date {
          > .date-disp-field {
            padding-top: 2px;
            font-size: 13px;
            width: 325px;
          }
        }
        &.column {
          > .column-disp-field {
            padding-top: 2px;
            margin-left: -25px;
            font-size: 13px;
          }
        }
      }
    }
    > .area {
      display: flex;
      margin-top: 16px;
      padding-bottom: 20px;
      border-bottom: solid 1px #{$light-grey};
      > .contents {
        &.left {
          width: 390px;
        }
        &.right {
          margin-left: 20px;
          width: 320px;
          &.memo {
            @include validate-message();
          }
        }
        > .row {
          display: flex;
          align-items: center;
          height: 33px;
          &.care {
            margin-top: 16px;
          }
          &.staff {
            margin-top: 16px;
          }
          &.owner-name {
            margin-top: 16px;
          }
          &.owner-name-kana {
            margin-top: 16px;
          }
          &.patient-name {
            margin-top: 16px;
          }
          > .info-label-wrapper {
            font-size: 15px;
            width: 90px;
            min-width: 90px;
            &.right {
              width: 60px;
              min-width: 60px;
            }
            &.owner-full-name {
              display: flex;
              align-items: center;
            }
            > .required {
              margin-left: 5px;
              font-size: 13px;
              color: #{$tomato};
            }
          }
          > .info-content-wrapper {
            &.right {
              @include validate-message();
            }
            > .show-id-input-field {
              display: flex;
              > .show-id-input {
                width: 160px;
                height: 33px;
                border-radius: 4px;
                border: solid 2px #{$light-grey};
                padding: 5px 8px;
                font-size: 13px;
                text-align: left;
                box-sizing: border-box;
                background-image: url('../../assets/images/search.png');
                background-repeat: no-repeat;
                background-position: center right 5px;
                @include hover();
                > .show-id-text {
                  width: 121px;
                  overflow: hidden;
                  white-space: nowrap;
                }
              }
              > .show-id-clear {
                margin-left: 20px;
              }
            }
            > .show-id-disp-field {
              padding-top: 2px;
              font-size: 13px;
              display: flex;
              align-items: center;
              gap: 0 20px;
              > .show-id-disp-text {
                width: 160px;
              }
            }
            > .name-input-field {
              display: flex;
              > .name-wrap {
                display: flex;
                align-items: center;
                width: 50%;
                &.right {
                  margin-left: 10px;
                }
                > .name-label {
                  width: 30px;
                  font-size: 13px;
                }
                > .name-input {
                  @include validate-message();
                  > .validate-provider .error {
                    left: -30px;
                    width: 165px;
                    font-size: 10px;
                  }
                }
              }
            }
            > .name-disp-field {
              padding-top: 2px;
              width: 300px;
              font-size: 13px;
              &.patient {
                width: auto;
              }
            }
          }
        }
        > .button-area {
          display: flex;
          margin-top: 16px;
          > .payment-button {
            margin-left: 20px;
          }
        }
      }
    }
  }
}
</style>
