<template>
  <div class="reservation-popup-edit-header">
    <div v-show="showStatus" class="status-wrapper">
      <div class="label">待合状況</div>
      <div class="value">
        <div v-if="canChangeStatus && lookOnlyFlg === 0" class="select">
          <base-select-box
            class="status-select"
            :value="dispReservation.waitingStatusId"
            :selectData="selectableWaitingStatuses"
            :styles="selectStyles"
            @input="changeWaitingStatus"
          />
        </div>
        <div v-else class="text">{{ statusName }}</div>
      </div>
    </div>
    <div class="right-wrapper">
      <div
        class="right-button repeat"
        @click="openRepeatPopup"
        data-test="repeat"
      >
        <font-awesome-icon class="icon repeat-icon" :icon="repeatIcon" />
        <div class="right-label">複数予約</div>
      </div>
      <div
        class="right-button show-owner"
        v-if="dispReservation.ownerId !== 0"
        @click="showOwner"
        data-test="show-owner"
      >
        <img
          src="@/assets/images/owners.png"
          class="icon"
          width="16px"
          height="18px"
          alt="owners"
        />
        <div class="right-label">飼主詳細</div>
      </div>
      <div
        class="right-button new-owner"
        :class="{ invalid: invalidFlg }"
        v-if="
          dispReservation.ownerId === 0 &&
            reservationId !== 0 &&
            lookOnlyFlg === 0
        "
        @click="newOwner"
        data-test="new-owner"
      >
        <img
          src="@/assets/images/add.png"
          class="icon"
          width="18px"
          height="18px"
          alt="add"
        />
        <div class="right-label">飼主登録</div>
      </div>
      <div
        class="right-button cancel-reservation"
        v-if="reservationId !== 0 && lookOnlyFlg === 0"
        :class="{ disabled: dispReservation.medicalRecordOriginalId !== 0 }"
        @click="cancelReservation"
        data-test="cancel-reservation"
        data-e2e="cancel-reservation"
      >
        <img
          src="@/assets/images/cancel_reservation.png"
          class="icon"
          width="17px"
          height="18px"
          alt="cancel-reservation"
        />
        <div class="right-label">予約のキャンセル</div>
      </div>
      <div
        class="right-button delete-reservation"
        v-if="reservationId !== 0 && lookOnlyFlg === 0"
        :class="{ disabled: dispReservation.medicalRecordOriginalId !== 0 }"
        @click="deleteReservation"
        data-test="delete-reservation"
        data-e2e="delete-reservation"
      >
        <img
          src="@/assets/images/trash_orange.png"
          class="icon"
          width="15px"
          height="18px"
          alt="trash-orange"
        />
        <div class="right-label">削除</div>
      </div>
    </div>
    <announce-popup
      v-if="announce.show"
      :type="announce.type"
      :title="announce.title"
      :buttons="announce.buttons"
      @cancel="announce.cancel"
      @decision="announce.decision"
      @close="announce.close"
      >{{ announce.content }}</announce-popup
    >
    <announce-popup
      v-if="emailConfirmationPopup.show"
      v-bind="emailConfirmationPopup"
      @cancel="closeEmailConfirmationPopup(false)"
      @decision="closeEmailConfirmationPopup(true)"
      >{{ emailConfirmationPopup.content }}</announce-popup
    >
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faClone } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

export default {
  name: 'ReservationPopupEditHeader',

  components: { BaseSelectBox, AnnouncePopup, FontAwesomeIcon },

  props: {
    beforeReservation: { type: Object },
    cancelMailAuto: { type: String },
    dispReservation: { type: Object },
    initialOwnerEmail: { type: String },
    invalidFlg: { type: Boolean, default: false },
    lookOnlyFlg: { type: Number },
    reservationId: { type: Number, required: true }
  },

  data() {
    return {
      announce: {
        cancel: false,
        decision: false,
        show: false
      },
      emailConfirmationPopup: {
        show: false,
        layerNumber: 2,
        type: 'alert',
        title: 'メール確認',
        content: '予約をキャンセルしました。飼主様に確認メールを送信しますか？',
        buttons: ['いいえ', 'はい']
      },
      emailConfirmationResolve: null,
      mailFlg: true,
      noDataFlg: false,
      repeatIcon: faClone,
      reservation: this.$store.getters['reservations/getDataById'](
        this.reservationId
      ),
      reservationForAll: this.dispReservation,
      reservationForOne: {
        ...this.dispReservation,
        groupKey: '',
        repeatKey: ''
      },
      reservations: this.$store.getters['reservations/getData'].filter(
        v => v.delFlg === 0 && v.cancelFlg === 0
      ),
      selectStyles: { width: '100px' },
      selectableWaitingStatuses: this.$store.getters[
        'waitingStatuses/getData'
      ].filter(v => v.reservationSelectableStatusFlg)
    }
  },

  computed: {
    ...mapGetters({
      medicalRecordByOId: 'medicalRecords/getDataByOriginalId',
      getWaitingStatus: 'waitingStatuses/getDataById'
    }),
    sameGroupReservations() {
      return this.reservations.filter(
        v => v.groupKey !== '' && v.groupKey === this.dispReservation.groupKey
      )
    },
    sameRepeatReservations() {
      const now = moment()
      return this.reservations.filter(
        v =>
          v.repeatKey !== '' &&
          v.repeatKey === this.dispReservation.repeatKey &&
          moment(v.date + v.startTime, 'YYYYMMDDHHmm').diff(now) > 0
      )
    },
    showStatus() {
      const show = this.dispReservation.date === moment().format('YYYYMMDD')
      return show
    },
    canChangeStatus() {
      const isNewReservation = !this.beforeReservation.id
      const beforeWaitingStatus = this.getWaitingStatus(
        this.beforeReservation.waitingStatusId
      )
      return (
        this.showStatus &&
        (isNewReservation ||
          beforeWaitingStatus.reservationChangeableStatusFlg > 0)
      )
    },
    statusName() {
      return this.getWaitingStatus(this.dispReservation.waitingStatusId).name
    }
  },

  methods: {
    showOwner() {
      if (this.lookOnlyFlg === 0) {
        this.$emit('showOwner')
      }
    },
    newOwner() {
      this.$emit('newOwner')
    },
    changeWaitingStatus(waitingStatusId) {
      this.$emit('changeWaitingStatus', waitingStatusId)
    },
    cancelReservation() {
      if (this.lookOnlyFlg === 0) {
        this.announce = {
          show: true,
          type: 'alert',
          title: 'キャンセルの確認',
          content: 'この予約を本当にキャンセルしますか？',
          buttons: ['キャンセルしない', 'キャンセルする'],
          cancel: this.close,
          decision: this.checkMultipleCancel,
          close: this.close
        }
      }
    },
    checkMultipleCancel() {
      const cancellableGroupReservations = this.sameGroupReservations.filter(
        v => v.medicalRecordOriginalId === 0
      )
      if (cancellableGroupReservations.length >= 2) {
        this.announce = {
          show: true,
          type: 'alert',
          title: '注意',
          content:
            '同時予約されている他の予約もキャンセルしますか？\n※カルテのない予約のみキャンセルできます。',
          buttons: ['他の予約はキャンセルしない', '他の予約もキャンセルする'],
          cancel: () => {
            this.doCancel(this.reservationForOne)
          },
          decision: () => {
            this.doCancel(this.reservationForAll)
          },
          close: this.close
        }
      } else {
        this.doCancel(this.reservationForOne)
      }
    },
    async doCancel(reservation) {
      if (this.cancelMailAuto === 'off') {
        this.mailFlg = false
      } else if (this.cancelMailAuto === 'check') {
        const upcomingReservation =
          this.reservation.date + this.reservation.startTime >=
          moment().format('YYYYMMDDHHmm')
        if (upcomingReservation && this.initialOwnerEmail)
          await this.openEmailConfirmationPopup()
      }
      const res = await this.$store.dispatch('reservations/cancel', {
        reservation,
        mailFlg: this.mailFlg
      })
      if (res.result === 'success') {
        this.$emit('delete', { action: 'cancel', sendMailFlg: res.sendMailFlg })
      } else {
        this.announce = {
          show: true,
          type: 'alert',
          title: 'キャンセル失敗',
          content: '予約のキャンセルに失敗しました',
          cancel: this.close,
          decision: this.close,
          close: this.close
        }
        if (res === 'no data' || res === 'no data in clinic') {
          this.announce.content =
            '予約のキャンセルに失敗しました。\nこの予約は既に削除またはキャンセルされています。'
          this.noDataFlg = true
        }
      }
    },
    deleteReservation() {
      this.announce = {
        show: true,
        type: 'alert',
        title: '削除の確認',
        content: 'この予約を本当に削除しますか？',
        buttons: ['削除しない', '削除する'],
        cancel: this.close,
        decision: this.checkMultipleDelete,
        close: this.close
      }
    },
    checkMultipleDelete() {
      const deletableGroupReservations = this.sameGroupReservations.filter(
        v => v.medicalRecordOriginalId === 0
      )
      const deletableRepeatReservations = this.sameRepeatReservations.filter(
        v => v.medicalRecordOriginalId === 0
      )
      if (deletableGroupReservations.length >= 2) {
        this.announce = {
          show: true,
          type: 'alert',
          title: '注意',
          content:
            '同時予約されている他の予約も削除しますか？\n※カルテのない予約のみ削除できます。',
          buttons: ['他の予約は削除しない', '他の予約も削除する'],
          cancel: () => {
            this.doDelete(this.reservationForOne)
          },
          decision: () => {
            this.doDelete(this.reservationForAll)
          },
          close: this.close
        }
      } else if (deletableRepeatReservations.length >= 2) {
        const reservationList = this.makeReservationList(
          deletableRepeatReservations
        )
        this.announce = {
          show: true,
          type: 'alert',
          title: '注意',
          content: `この予約は複数予約の一部です。他の予約も削除しますか？\n※カルテのない予約のみ削除できます。\n${reservationList}`,
          buttons: ['他の予約は削除しない', '他の予約も削除する'],
          cancel: () => {
            this.doDelete(this.reservationForOne)
          },
          decision: () => {
            this.doDelete(this.reservationForAll)
          },
          close: this.close
        }
      } else {
        this.doDelete(this.reservationForOne)
      }
    },
    async doDelete(reservation) {
      const res = await this.$store.dispatch('reservations/delete', reservation)
      const type = reservation.repeatKey ? 'delete-repeat' : 'delete'
      if (res === true) {
        this.$emit('delete', { action: type, sendMailFlg: false })
      } else {
        this.announce = {
          show: true,
          type: 'alert',
          title: '削除失敗',
          content: '予約の削除に失敗しました',
          cancel: this.close,
          decision: this.close,
          close: this.close
        }
        if (res === 'no data in clinic') this.noDataFlg = true
      }
    },
    close() {
      this.announce = { show: false }
      if (this.noDataFlg) this.$store.dispatch('timeTable/deletePopup')
    },
    openRepeatPopup() {
      this.$emit('openRepeatPopup')
    },
    makeReservationList(reservations) {
      let reservationList = ''
      reservations.forEach(r => {
        reservationList +=
          '\n' +
          moment(r.date + r.startTime, 'YYYYMMDDHHmm').format(
            'YYYY年MM月DD日　HH:mm'
          ) +
          '～' +
          moment(r.endTime, 'HHmm').format('HH:mm')
      })
      return reservationList
    },
    async openEmailConfirmationPopup() {
      this.emailConfirmationPopup.show = true
      await new Promise(resolve => (this.emailConfirmationResolve = resolve))
    },
    closeEmailConfirmationPopup(flg) {
      this.mailFlg = flg
      this.emailConfirmationPopup.show = false
      this.emailConfirmationResolve()
      this.emailConfirmationResolve = null
    }
  }
}
</script>
<style lang="scss" scoped>
.reservation-popup-edit-header {
  width: 100%;
  height: 35px;
  margin-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px #{$light-grey};
  font-size: 13px;
  > .status-wrapper {
    margin-left: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    > .label {
      width: 90px;
      text-align: left;
      font-size: 15px;
    }
  }
  > .right-wrapper {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
    height: 100%;
    > .right-button {
      justify-content: center;
      display: flex;
      flex-direction: row;
      margin-right: 10px;
      cursor: pointer;
      padding: 0 5px;
      &.invalid,
      &.disabled {
        pointer-events: none;
        background-color: #{$light-grey};
        color: #{$brown-gray};
        border-radius: 6px;
        > .icon {
          opacity: 0.4;
        }
      }
      > .icon {
        height: 18px;
        margin: auto;
        &.repeat-icon {
          color: #{$pumpkin};
        }
      }
      > .right-label {
        margin: auto;
        margin-left: 10px;
      }
    }
  }
}
</style>
